<script setup></script>
<template>
  <div class="page">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  position: relative;
  width: 100%;
  margin: -11px auto 0 auto;
  padding-bottom: 65px;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    height: 90px;
    background: linear-gradient(89.27deg, #191c2d 14.86%, #0f111d 98.93%);
  }
}
</style>
