<script setup></script>

<template>
  <router-view :key="$route.fullPath" />
</template>

<style lang="scss">
:root {
  --primary: #282828;
  --secondary: #f0c515;
  --green1: #1ab43b;
  --yellow1: #f9cc00;
  --dark1: #050a0e;
  --dark2: #1a1a1a;
  --dark3: #383838;
  --dark4: #5a5a5a;
  --dark5: #868686;
  --dark-blue: #12141f;
  --dark-gradient: linear-gradient(0deg, #011c2a, #01111a);
  --red1: #eb2f2f;
  --box-shadow-default: 0 30px 54px 0 rgba(35, 54, 70, 0.12);
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: fontRegular;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
}

html,
body {
  min-width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
a,
svg,
div {
  font-size: 14px;
  color: var(--primary);
}

.color-primary {
  color: var(--primary) !important;
}
.color-secondary {
  color: var(--secondary) !important;
}
.color-green1 {
  color: var(--vivid-green1) !important;
}
.color-yellow1 {
  color: var(--yellow1) !important;
}
.color-red1 {
  color: var(--red1) !important;
}
.color-white {
  color: #fff !important;
}

.desaturate {
  filter: saturate(0) !important;
}

@font-face {
  font-family: "fontRegular";
  src: url("../public/fonts/Montserrat-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontLight";
  src: url("../public/fonts/Montserrat-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontMedium";
  src: url("../public/fonts/Coolvetica.otf");
  font-display: swap;
}

@font-face {
  font-family: "fontBold";
  src: url("../public/fonts/Montserrat-Bold.ttf");
  font-display: swap;
}

img {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.mr-left {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.flex {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 35px auto;
  background-color: #e9e9e9;
}

.swiper-pagination {
  display: none !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: unset !important;
  border: 1px var(--primary) solid !important;
}

.swiper-pagination-bullet-active {
  background: var(--secondary) !important;
}

.swiper-button-disabled {
  background-color: transparent !important;
  span {
    color: #c9c9c9 !important;
  }
  .material-design-icon .material-design-icon__svg {
    color: #c9c9c9 !important;
  }
}

select {
  background: transparent;
}

.input-item {
  position: relative;
  width: 100%;
  margin: 1.35rem 0 0 0;

  span {
    position: relative;
    display: block;
    font-family: fontLight;
    font-size: 0.9rem;
    color: var(--primary);

    .blue {
      color: var(--blue);
    }
  }

  input,
  select,
  select option {
    position: relative;
    font-family: fontRegular;
    font-size: 1rem;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    padding: 0.25rem 0.85rem;
    background-color: #f1f1f1;
    color: var(--primary);
    &::placeholder {
      color: var(--dark5);
      font-family: fontLight;
      font-size: 1rem;
    }
  }
  &:disabled {
    cursor: not-allowed;
    color: #666;
  }
  select option:disabled {
    color: #aaa !important;
  }
  textarea {
    position: relative;
    width: 100%;
    height: 90px;
    padding: 0.45rem 0.85rem;
    background-color: #f1f1f1;
    font-family: fontRegular;
    font-size: 1rem;
    color: var(--primary);
    resize: none;
    &:disabled {
      cursor: not-allowed;
      color: #666;
    }
    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      outline: 1px solid slategrey;
    }
  }
}

.input-holder {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 0.6em;
  margin-top: 3px;
  input,
  select {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 50px 0 15px;
    font-family: fontRegular;
    font-size: 1.1em;
    background-color: transparent;
    appearance: none;
  }
  .unit-holder {
    position: absolute;
    width: 50px;
    height: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    pointer-events: none;
    span {
      color: var(--primary);
    }
  }
}

.inputs-row .input-item {
  width: 45%;
}

.table-holder {
  position: relative;
  max-width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #111;
    outline: 1px solid slategrey;
  }
  @media only screen and (min-width: 1160px) {
    overflow-x: initial;
  }
}

table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  tr {
    position: relative;
    width: 100%;
    padding-left: 15px;
    background-color: #fff;
    z-index: 1;
    &.pointer {
      transition: box-shadow 0.15s ease, transform 0.15s ease;
      &:hover {
        z-index: 2;
        box-shadow: 0 8px 15px -1px rgba(35, 54, 70, 0.15);
        transform: scale(1.03);
      }
    }
    th:first-child,
    td:first-child {
      border-radius: 6px 0 0 6px;
      padding-left: 10px;
      @media screen and (min-width: 721px) {
        width: 40%;
      }
      &.width-initial {
        width: initial;
      }
    }
    th:last-child,
    td:last-child {
      border-radius: 0 6px 6px 0;
      padding-right: 5px;
    }
  }
  th {
    text-align: left;
    height: 40px;
    padding-left: 10px;
    color: var(--primary);
  }
  td {
    padding: 8px 0 8px 10px;
    color: var(--primary);
    * {
      color: var(--primary);
    }
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: #f2f3f4;
    }
  }
}

.table-action-holder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  padding-right: 10px;
  svg {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.card-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card-header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 2rem;
  @media only screen and (max-width: 720px) {
    margin-bottom: 2rem;
  }
  .item {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 0;
    width: 44px;
    height: 44px;
    border-radius: 14px;
    background-color: var(--secondary);
    span {
      transform: translate3d(8px, 8px, 0) scale(1.6);
    }
  }
  span {
    font-size: 1.05rem;
  }
}

.header-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  .icon-holder {
    padding: 0.5rem;
  }
}

.btn {
  position: relative;
  padding: 16px 22px;
  border-radius: 0.8em;
  background-color: var(--secondary);
  margin: 25px auto 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.25s ease;
  &:hover {
    transform: translateY(-3px);
  }

  span {
    font-family: fontRegular;
    font-size: 1.1em;
    color: #000;
    text-align: center;
  }

  &.border {
    padding: 16px 22px;
    border: 1px solid var(--primary);
    background-color: transparent;
    box-shadow: none;
    span {
      color: var(--primary);
    }
  }

  &.inverse {
    padding: 16px 22px;
    background-color: transparent;
    box-shadow: none;
    span {
      color: var(--primary);
    }
  }

  &.icon {
    padding: 11px 22px;
    span:first-child {
      margin-right: 15px;
    }
  }
}

.material-design-icon {
  position: relative;
  top: 3px;
}

.loading {
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 22px;
  width: 22px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.loading.white {
  border: 2px solid #fff;
  border-right-color: transparent;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-anim1 {
  animation-name: anim1;
  animation-duration: 0.3s;
}

.page-anim2 {
  animation-name: anim2;
  animation-duration: 0.3s;
}

.page-anim3 {
  animation-name: anim3;
  animation-duration: 0.3s;
}

@keyframes anim1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim2 {
  from {
    opacity: 0;
    transform: translateX(17vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes anim3 {
  from {
    opacity: 0;
    transform: translateY(25vw);
  }
  to {
    opacity: 1;
    transform: translateY(0vw);
  }
}
</style>
