<script setup>
	import { inject, watch } from "vue";

	import Card from "@/baseComponents/Card";

	import ChevronLeft from "vue-material-design-icons/ChevronLeft";
	import ChevronRight from "vue-material-design-icons/ChevronRight";
	import CommentIcon from "vue-material-design-icons/CommentQuestion";
	import UserIcon from "vue-material-design-icons/Account";
	import ArrowIcon from "vue-material-design-icons/ArrowRightBottom";
	import ImageIcon from "vue-material-design-icons/ImageFilterHdr";
	import WarningIcon from "vue-material-design-icons/AlertCircleOutline";

	import "swiper/css";
	import "swiper/css/bundle";
	import Swiper, { Navigation } from "swiper";
	Swiper.use([Navigation]);

	const $formatDate = inject("$formatDate");

	const emit = defineEmits(["openChat"]);

	const props = defineProps({
		loadingQuestion: {
			type: Boolean,
			default: false,
		},
		questions: {
			type: Array,
			default: () => {
				return [];
			},
		},
	});

	watch(
		() => props.questions,
		(value) => {
			if (value) {
				startQuestionSlider();
			}
		}
	);

	const startQuestionSlider = () => {
		setTimeout(() => {
			const questionSlider = new Swiper("#questionSlider", {
				slidesPerView: "auto",
				spaceBetween: 35,
				freeMode: true,
				grabCursor: true,
				navigation: {
					nextEl: "#questionNext",
					prevEl: "#questionPrev",
				},
			});
		}, 150);
	};

	const openChat = (eventProductId, productId, userId, questionIndex) => {
		emit("openChat", eventProductId, productId, userId, questionIndex);
	};
</script>

<template>
	<Card class="card-holder overflow-hidden">
		<div class="card-header">
			<div class="item mobile-hidden">
				<CommentIcon :size="30" fillColor="var(--primary)" />
			</div>
			<span>Últimas perguntas dos usuários</span>
			<div
				v-show="props.questions.length > 0 && !props.loadingQuestion"
				class="controls"
			>
				<div class="control" id="questionPrev">
					<ChevronLeft :size="34" />
				</div>
				<div class="control" id="questionNext">
					<ChevronRight :size="34" />
				</div>
			</div>
		</div>
		<div v-show="props.loadingQuestion" class="flex flex-center mt-2 mb-2">
			<div class="loading"></div>
		</div>
		<span
			class="flex flex-center mt-2 mb-2"
			v-show="props.questions.length <= 0 && !props.loadingQuestion"
		>
			Nenhuma pergunta pendente.
		</span>
		<div
			v-show="props.questions.length > 0 && !props.loadingQuestion"
			class="questions-holder swiper-container"
			id="questionSlider"
		>
			<div class="swiper-wrapper animated fadeIn">
				<div
					v-for="(question, index) in props.questions"
					:key="question.id"
					class="card-question swiper-slide"
					:class="{ blocked: question.status === 'Bloqueada' }"
				>
					<div class="question-header">
						<div class="question-img">
							<img
								v-if="question.product.coverImage"
								:src="question.product.coverImage"
								alt="Imagem pergunta"
							/>
							<ImageIcon v-else fillColor="#ccc" :size="40" />
						</div>
						<span>{{ question.product.name }}</span>
					</div>
					<div class="question-body">
						<div class="user-info">
							<div class="user-img">
								<img
									v-if="question.userImg"
									:src="question.userImg"
									alt="Imagem usuário"
								/>
								<UserIcon v-else fillColor="#545454" :size="20" />
							</div>
							<span class="user-title">{{
								question.userName.split(" ").shift()
							}}</span>
							<span class="user-date">{{ $formatDate(question.timestamp) }}</span>
						</div>
						<div class="user-question">
							<ArrowIcon fillColor="#545454" />
							<div v-if="question.status === 'Bloqueada'" class="blocked-msg">
								<WarningIcon fillColor="#ED193F" :size="30" />
								<div class="msg">
									Mensagem bloqueada por violar as diretrizes da plataforma.
								</div>
							</div>
							<span v-else class="question">{{ question.description }}</span>
						</div>
					</div>
					<div class="question-footer">
						<div
							@click="
								openChat(question.eventId, question.productId, question.userId, index)
							"
							class="btn border"
						>
							<span>Responder</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<router-link
			to="/perguntas"
			v-show="props.questions.length > 0 && !props.loadingQuestion"
			class="btn"
		>
			<span>Ver todos</span>
		</router-link>
	</Card>
</template>

<style lang="scss" scoped>
	.overflow-hidden {
		overflow: hidden !important;
	}

	.controls {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2em;
		margin-left: auto;
		@media only screen and (max-width: 720px) {
			gap: 1.2em;
		}
		.control {
			position: relative;
			cursor: pointer;
			display: grid;
			place-items: center;
			padding: 0.1em;
			&.swiper-button-disabled {
				opacity: 0.4;
			}
		}
	}

	.divider {
		margin: 25px auto !important;
	}

	.questions-holder {
		position: relative;
		width: 100%;
	}

	.card-question {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 380px;
		padding: 1rem 1.2rem;
		border: 1px solid #dadada;
		border-radius: 10px;
		&.blocked {
			border-color: #ed193f;
			.blocked-msg {
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 1rem;
				.msg {
					color: #ed193f;
				}
			}
		}
		@media only screen and (max-width: 720px) {
			width: 100%;
		}
		.question-header {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			gap: 20px;
			span {
				width: 80%;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			.question-img {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #eee;
				width: 52px;
				height: 52px;
				border-radius: 4px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 4px;
				}
			}
		}
		.question-body {
			position: relative;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin: 1.3rem 0;
			gap: 10px;
			.user-info {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.user-img {
					position: relative;
					width: 24px;
					height: 24px;
					background-color: #ccc;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					margin-right: 15px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 50%;
					}
				}
				.user-date {
					font-size: 0.85rem;
					font-family: fontLight;
					font-style: italic;
					margin-left: auto;
				}
			}
			.user-question {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 10px;
				margin-left: 5px;
				span {
					font-family: fontLight;
					font-size: 0.8rem;
				}
				.question {
					width: 100%;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
		}
		.question-footer {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			.btn {
				margin: 0;
				padding: 12px 15px;
				border: 1px solid var(--primary);
				span {
					font-size: 0.8rem;
					color: var(--primary) !important;
				}
			}
		}
	}
</style>
