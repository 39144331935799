<template>
  <div class="card">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 2em;
  border-radius: 1.2em;
  box-shadow: var(--box-shadow-default);
  margin: 10px auto 0 auto;
}
</style>
