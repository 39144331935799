<script setup>
import { ref, inject, watch, defineEmits, computed } from "vue";
import http from "@/http";

import Card from "@/baseComponents/Card";
import CurrencyInput from "@/baseComponents/CurrencyInput";

import Payment from "@/components/Payment.vue";

import FileIcon from "vue-material-design-icons/FileClock";
import ImageIcon from "vue-material-design-icons/ImageFilterHdr";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
import CancelIcon from "vue-material-design-icons/Close";
import UserIcon from "vue-material-design-icons/Account";

const emit = defineEmits(["setQuote", "cancelQuote", "createPayment"]);
const swal = inject("$swal");
const $formatDate = inject("$formatDate");
const quote = ref({});
const user = ref({});
const showPaymentInfo = ref(false);
const showClientData = ref(false);

const props = defineProps({
  quoteData: {
    type: Object,
    default: () => {
      return {};
    },
  },
  updatingQuote: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.quoteData,
  (value) => {
    quote.value = { ...value };
  },
  { deep: true }
);

const setQuote = () => {
  if (props.updatingQuote.value) return;
  if (!validQuote()) return;

  swal
    .fire({
      icon: "info",
      title: "Definir orçamento",
      text: "Ao definir o orçamento não será mais possível editar seu valor nem a mensagem final para o cliente. Confira se os dados estão realmente corretos antes de prosseguir.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      confirmButtonText: "Definir orçamento",
    })
    .then((response) => {
      if (response?.isConfirmed) {
        quote.value.eventProductId = quote.value.id;
        quote.value.message = quote.value.contractorMessage;
        emit("setQuote", quote.value);
      }
    });
};

const cancelQuote = () => {
  if (props.updatingQuote.value) return;

  swal
    .fire({
      icon: "error",
      title: "Cancelar orçamento",
      text: "Esta ação não poderá ser desfeita. Certeza que deseja cancelar este orçamento?",
      showCancelButton: true,
      cancelButtonText: "Voltar",
      reverseButtons: true,
      confirmButtonText: "Cancelar orçamento",
      confirmButtonColor: "#e8215e",
    })
    .then((response) => {
      if (response?.isConfirmed) {
        quote.value.eventProductId = quote.value.id;
        emit("cancelQuote", quote.value);
      }
    });
};

const validQuote = () => {
  if (!quote.value.contractorMessage) {
    fieldRequiredAlert("Por favor, insira uma mensagem final para o cliente.");
    return false;
  }
  if (!quote.value.price) {
    fieldRequiredAlert("Por favor, insira um valor para o orçamento.");
    return false;
  }
  return true;
};

const fnShowPaymentInfo = () => {
  if (quote.value.requirePayment &&
    (quote.value.paymentStatus === "Pendente" ||
    quote.value.paymentStatus === "Cancelado" ||
    quote.value.paymentStatus === "Reembolsado" ||
    quote.value.paymentStatus === "Rejeitado")) {
    showPaymentInfo.value = true;
  } else {
    showPaymentInfo.value = false;
    http.get("Event/Product/Client?eventProductId=" + quote.value.id)
      .then(response => {
        user.value = response.data;
        user.value.fullAddress = `${user.value.address}, ${user.value.number}, ${user.value.neighborhood} - ${user.value.city}/${user.value.state}`;
      })
  }

  if (!quote.value.requirePayment || quote.value.paymentStatus === "Confirmado") {
    showClientData.value = true;
  }
};

const statusColor = computed(() => {
  if (quote.value.status === "Solicitado") return "#fca842";
  if (quote.value.status === "Definido") return "#235798";
  if (quote.value.status === "Aprovado") return "#1FB42E";
  if (quote.value.status === "Reprovado") return "#e8215e";
  if (quote.value.status === "Cancelado") return "#a1a1a1";

  return "#555";
});

const paymentStatusColor = computed(() => {
  if (quote.value.paymentStatus === "Pendente") return "#fca842";
  if (quote.value.paymentStatus === "Aguardando confirmação") return "#235798";
  if (quote.value.paymentStatus === "Confirmado") return "#1FB42E";
  if (quote.value.paymentStatus === "Rejeitado") return "#e8215e";
  if (
    quote.value.paymentStatus === "Cancelado" ||
    quote.value.paymentStatus === "Reembolsado"
  )
    return "#a1a1a1";

  return "#555";
});

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};

const createPayment = (payment) => {
  emit("createPayment", payment);
};
</script>

<template>
  <Card>
    <div class="card-holder">
      <div class="card-header">
        <div class="item mobile-hidden">
          <FileIcon :size="30" fillColor="var(--primary)" />
        </div>
        <span>Detalhes da solicitação</span>
      </div>
      <div class="quote-details mb-2">
        <div v-if="quote.name" class="quote-title">
          <span class="title">Serviço de interesse:</span>
          <span class="date"
            >solicitado em <b>{{ $formatDate(quote.timestamp) }}</b></span
          >
        </div>
        <div v-if="quote.name" class="quote-card">
          <div class="quote-img">
            <img
              v-if="quote.coverImage"
              :src="quote.coverImage"
              alt="Imagem orçamento"
            />
            <ImageIcon v-else fillColor="#ccc" :size="40" />
          </div>
          <div>
            <div class="quote-name">{{ quote.name }}</div>
            <div v-if="quote.quantity" class="quote-quantity">
              {{ `Quantidade solicitada: ${quote.quantity}` }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="quote.userMessage" class="quote-details mb-2">
        <div class="quote-title">
          <span class="title">Detalhes do evento do cliente:</span>
        </div>
        <div class="quote-card">
          <span>{{ quote.userMessage }}</span>
        </div>
      </div>
      <div class="quote-final">
        <div class="quote-title">
          <span v-if="quote.status === 'Solicitado'" class="title"
            >Definir orçamento:</span
          >
          <span v-else class="title">Orçamento detalhes:</span>
          <div class="line"></div>
        </div>
        <div class="input-item mb-2">
          <span>Mensagem final para o cliente *</span>
          <textarea
            v-model="quote.contractorMessage"
            placeholder="Faça suas considerações finais, observações ou qualquer informação que julgue relevante para o cliente."
            :disabled="quote.status !== 'Solicitado'"
          ></textarea>
        </div>
        <div class="quote-send mb-2">
          <div class="input-item">
            <span>Valor final para o cliente *</span>
            <div class="input-holder icon-left">
              <CurrencyInput
                v-model="quote.price"
                :disabled="quote.status !== 'Solicitado'"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>R$</span>
              </div>
            </div>
          </div>
          <div
            v-if="quote.status === 'Solicitado'"
            @click="setQuote"
            class="btn icon"
          >
            <span v-show="!props.updatingQuote">Definir orçamento</span>
            <ChevronRightIcon
              v-show="!updatingQuote"
              fillColor="var(--primary)"
            />
            <div v-show="props.updatingQuote" class="loading white"></div>
          </div>

          <div
            v-if="quote.status === 'Definido'"
            @click="cancelQuote"
            class="btn icon"
            style="background-color: #e8215e"
          >
            <span v-show="!props.updatingQuote" style="color: #fff"
              >Cancelar orçamento</span
            >
            <CancelIcon v-show="!updatingQuote" fillColor="#fff" />
            <div v-show="props.updatingQuote" class="loading white"></div>
          </div>
        </div>
        <div class="quote-status">
          <div class="status">
            <div class="ball" :style="`background-color:${statusColor}`"></div>
            <span :style="`color:${statusColor}`">{{
              `Orçamento ${quote.status}`
            }}</span>
          </div>

          <div v-show="quote.status === 'Aprovado' && quote.requirePayment" class="status mt-1">
            <div
              class="ball"
              :style="`background-color:${paymentStatusColor}`"
            ></div>
            <span :style="`color:${paymentStatusColor}`">{{
              `Pagamento ${quote.paymentStatus}`
            }}</span>
          </div>
          <div
            @click="fnShowPaymentInfo"
            v-show="
              quote.status === 'Aprovado' && !showPaymentInfo && !showClientData
            "
            class="btn icon"
            style="background: var(--primary)"
          >
            <span style="color: #fff">Ver dados para contato</span>
            <ChevronRightIcon fillColor="#fff" />
          </div>
          <span
            v-show="quote.status === 'Definido'"
            class="status-info"
            :style="`color:${statusColor}`"
            >Aguardando a confirmação do cliente.</span
          >
        </div>
      </div>

      <div v-show="showPaymentInfo || showClientData" class="divider"></div>

      <Payment
        v-show="showPaymentInfo"
        :quotePrice="quote.price"
        :eventProductId="quote.id"
        :contractorEmail="quote.contractor?.email"
        :updatingQuote="props.updatingQuote"
        @createPayment="createPayment"
      />

      <transition enter-active-class="animated fadeInLeft">
        <div v-show="showClientData" class="client-info-holder">
          <div class="title-holder">
            <UserIcon fillColor="var(--primary)" :size="32" />
            <h1>Dados de contato do usuário</h1>
          </div>
          <div class="data-holder">
            <div class="info-data">
              <span>Nome do cliente</span>
              <div class="value">{{user.name}}</div>
            </div>
            <div class="space-between">
              <div class="info-data">
                <span>E-mail</span>
                <div class="value">{{user.email}}</div>
              </div>
              <div class="info-data">
                <span>Telefone</span>
                <div class="value">{{user.phoneNumber}}</div>
              </div>
            </div>
            <div class="info-data">
              <span>Endereço</span>
              <div class="value">{{user.fullAddress}}</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.quote-details {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 7px;
  .quote-title {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date {
      font-family: fontLight;
    }
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .quote-card {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 12px;
    padding: 10px;
    gap: 20px;
    .quote-img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
      width: 52px;
      height: 52px;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    .quote-name {
      font-family: FontMedium;
      font-size: 1rem;
    }
    .quote-quantity {
      font-size: 0.9rem;
    }
  }
}

.title {
  font-size: 1rem;
  font-family: fontMedium;
  color: var(--primary);
  letter-spacing: 1px;
}

.quote-final {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .quote-title {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .title {
      white-space: nowrap;
    }
    .line {
      height: 1px;
      width: 70%;
      background-color: #ddd;
    }
  }
  .input-item {
    span {
      position: relative;
      display: block;
      font-family: fontLight;
      font-size: 0.85rem;
      color: #868686;
      margin-bottom: 5px;
    }
    textarea {
      border-radius: 12px;
      font-size: 0.95rem;
      &::placeholder {
        padding-top: 5px;
        color: var(--primary);
        font-family: fontLight;
        font-size: 0.85rem;
      }
    }
  }
  .quote-send {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
    .input-item {
      width: 40%;
      margin: 0;
      @media only screen and (max-width: 720px) {
        width: 100%;
      }
    }
    .icon-left input {
      padding: 0 20px 0 60px;
      &:disabled {
        cursor: not-allowed;
        color: #666;
      }
    }
    .unit-holder {
      position: absolute;
      width: 50px;
      height: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      pointer-events: none;
      .line {
        position: absolute;
        width: 1px;
        height: 60%;
        background-color: #b1b1b1;
        left: 45px;
      }
      span {
        font-size: 1rem;
        color: #b1b1b1;
        margin: 0;
      }
    }
    .btn {
      margin: 0;
    }
  }
  .quote-status {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .status {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      gap: 15px;
      .ball {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--secondary);
      }
      span {
        font-size: 0.9rem;
      }
    }
    .status-info {
      margin-top: 0.5rem;
    }
  }
}

.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  h1 {
    position: relative;
    top: 2px;
  }
}

.data-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-top: 1rem;
  .space-between {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  .info-data {
    position: relative;
    span {
      font-family: fontLight;
    }
    .value {
      font-family: fontRegular;
      padding: 10px 16px;
      margin-top: 3px;
      background-color: #efefef;
      border-radius: 5px;
    }
  }
}
</style>
