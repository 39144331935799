<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

import http from "@/http";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";

import Quote from "@/components/quoteDetails/Quote";
import Message from "@/components/quoteDetails/Message";

const swal = inject("$swal");
const router = useRouter();
const quote = ref({});
const loading = ref(true);
const updatingQuote = ref(false);

http
  .get(`event/product?eventProductId=${router.currentRoute.value.params.id}`)
  .then((response) => {
    quote.value = response?.data;
  })
  .catch((err) => {
    console.log(err);
    fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
  })
  .finally(() => {
    loading.value = false;
  });

const updateQuote = (quoteObj) => {
  updatingQuote.value = true;
  http
    .post("event/product/define", quoteObj)
    .then((response) => {
      quote.value = {};
      quote.value = response?.data;
      // sucessAlert("Orçamento enviado ao cliente!");
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      updatingQuote.value = false;
    });
};

const cancelQuote = (quoteObj) => {
  updatingQuote.value = true;
  http
    .post("event/product/cancel", quoteObj)
    .then((response) => {
      quote.value = response?.data;
      // sucessAlert("Orçamento cancelado.");
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      updatingQuote.value = false;
    });
};

const createPayment = (payment) => {
  updatingQuote.value = true;
  http
    .post("event/product/payment", payment)
    .then((response) => {
      quote.value = response?.data;
      if (quote.value.paymentStatus === "Pendente")
        if (quote.value.paymentStatus === "Aguardando confirmação")
          // sucessAlert(
          // 	"Seu pagamento está sendo processado, aguarde sua confirmação."
          // );
          if (quote.value.paymentStatus === "Confirmado") {
            // sucessAlert(
            // 	"Seu pagamento está sendo processado, aguarde sua confirmação."
            // );
            // sucessAlert("Pagamento realizado com sucesso.");
            window.location.reload();
          }
    })
    .catch((err) => {
      console.log(err);
      errorAlert(err.response.data);
    })
    .finally(() => {
      updatingQuote.value = false;
    });
};

// const sucessAlert = (msg) => {
// 	swal.fire({
// 		toast: true,
// 		icon: "success",
// 		text: msg,
// 		position: "top-right",
// 		showConfirmButton: false,
// 		timer: 3500,
// 		timerProgressBar: true,
// 	});
// };

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
const errorAlert = (msg) => {
  swal
    .fire({
      position: "center",
      icon: "error",
      text: msg,
      showConfirmButton: true,
    })
    .then((result) => {
      window.location.reload();
    });
};
</script>

<template>
  <Header />
  <Page>
    <div class="flex">
      <div v-show="loading" class="loading-holder flex flex-center">
        <div class="loading"></div>
      </div>
      <Quote
        class="animated zoomIn"
        style="animation-delay: 200ms"
        v-show="!loading"
        :quoteData="quote"
        :updatingQuote="updatingQuote"
        @setQuote="updateQuote"
        @cancelQuote="cancelQuote"
        @createPayment="createPayment"
      />
      <Message
        class="animated zoomIn"
        style="animation-delay: 400ms"
        v-show="!loading"
        :messagesData="quote.messages"
        :eventId="quote.eventId"
        :productId="quote.productId"
        :userId="quote.userId"
      />
    </div>
  </Page>
</template>

<style lang="scss" scoped>
.flex {
  gap: 2.3rem;
  align-items: flex-start;
  @media only screen and (max-width: 1159px) {
    flex-direction: column;
  }
}

.loading-holder {
  margin-top: 160px;
  @media only screen and (max-width: 1159px) {
    align-items: center;
  }
}
</style>
