<script setup>
import { ref, inject } from "vue";

import http from "@/http";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";
import Messages from "@/components/Messages";

import CommentIcon from "vue-material-design-icons/CommentQuestion";
import UserIcon from "vue-material-design-icons/Account";
import ArrowIcon from "vue-material-design-icons/ArrowRightBottom";
import ImageIcon from "vue-material-design-icons/ImageFilterHdr";
import WarningIcon from "vue-material-design-icons/AlertCircleOutline";

const swal = inject("$swal");
const $formatDate = inject("$formatDate");

const loading = ref(true);
const loadingMore = ref(false);
const page = ref(1);
const questions = ref([]);
const allQuestionsFetched = ref(false);
const showChat = ref(false);
const selectedEventId = ref("");
const selectedProductId = ref("");
const selectedUserId = ref("");

const getQuestions = () => {
  if (loadingMore.value) return;
  if (page.value > 1) loadingMore.value = true;

  http
    .get(`message/list/conversation?page=${page.value}&take=20`)
    .then((response) => {
      questions.value = [...questions.value, ...response?.data?.list];

      if (response.data?.pages > page.value) {
        page.value++;
      } else {
        allQuestionsFetched.value = true;
      }
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      loading.value = false;
      loadingMore.value = false;
    });
};

getQuestions();

const openChat = (eventId, productId, userId) => {
  showChat.value = true;
  selectedEventId.value = eventId;
  selectedProductId.value = productId;
  selectedUserId.value = userId;
};

const closeChat = () => {
  showChat.value = false;
};

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <Header />
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div @click="closeChat" v-show="showChat" class="bg-cover"></div>
  </transition>

  <Messages
    @close="closeChat"
    :show="showChat"
    :eventId="selectedEventId"
    :productId="selectedProductId"
    :userId="selectedUserId"
  />

  <Page>
    <Card class="card-holder overflow-hidden animated zoomIn">
      <div class="card-header">
        <div class="item mobile-hidden">
          <CommentIcon :size="30" fillColor="var(--primary)" />
        </div>
        <span>Perguntas dos usuários</span>
      </div>
      <div v-show="loading" class="flex flex-center mt-2 mb-2">
        <div class="loading"></div>
      </div>
      <span
        class="flex flex-center mt-2 mb-2"
        v-show="questions.length <= 0 && !loading"
      >
        Você ainda não possuí nenhuma pergunta.
      </span>
      <div v-show="questions.length > 0 && !loading" class="questions-holder">
        <div
          v-for="question in questions"
          :key="question.id"
          class="card-question"
          :class="{ blocked: question.status === 'Bloqueada' }"
        >
          <div class="question-header">
            <div class="question-img">
              <img
                v-if="question.product.coverImage"
                :src="question.product.coverImage"
                alt="Imagem pergunta"
              />
              <ImageIcon v-else fillColor="#ccc" :size="40" />
            </div>
            <span>{{ question.product.name }}</span>
          </div>
          <div class="question-body">
            <div class="user-info">
              <div class="user-img">
                <img
                  v-if="question.userImg"
                  :src="question.userImg"
                  alt="Imagem usuário"
                />
                <UserIcon v-else fillColor="#545454" :size="20" />
              </div>
              <span class="user-title">{{
                question.userName.split(" ").shift()
              }}</span>
              <span class="user-date">{{
                $formatDate(question.timestamp)
              }}</span>
            </div>
            <div class="user-question">
              <ArrowIcon fillColor="#545454" />
              <div v-if="question.status === 'Bloqueada'" class="blocked-msg">
                <WarningIcon fillColor="#ED193F" :size="30" />
                <div class="msg">
                  Mensagem bloqueada por violar as diretrizes da plataforma.
                </div>
              </div>
              <span v-else class="question">{{ question.description }}</span>
            </div>
          </div>
          <div class="question-footer">
            <div
              @click="
                openChat(question.eventId, question.productId, question.userId)
              "
              class="btn border"
            >
              <span>Responder</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!allQuestionsFetched && !loading"
        @click="getQuestions"
        class="btn"
      >
        <span v-show="!loadingMore">Carregar mais</span>
        <div v-show="loadingMore" class="loading white"></div>
      </div>
    </Card>
  </Page>
</template>

<style lang="scss" scoped>
.divider {
  margin: 25px auto !important;
}

.questions-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 1.5rem;
  @media only screen and (max-width: 720px) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

.card-question {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.2rem;
  border: 1px solid #dadada;
  border-radius: 10px;
  &.blocked {
    border-color: #ed193f;
    .blocked-msg {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      .msg {
        color: #ed193f;
      }
    }
  }
  .question-header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
    span {
      width: 80%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .question-img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
      width: 52px;
      height: 52px;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
  .question-body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1.3rem 0;
    gap: 10px;
    .user-info {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .user-img {
        position: relative;
        width: 24px;
        height: 24px;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .user-date {
        font-size: 0.85rem;
        font-family: fontLight;
        font-style: italic;
        margin-left: auto;
      }
    }
    .user-question {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      margin-left: 5px;
      span {
        font-family: fontLight;
        font-size: 0.8rem;
      }
      .question {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .question-footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      margin: 0;
      padding: 12px 15px;
      span {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
