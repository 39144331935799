import axios from "axios";
import authentication from "@/util/authentication";

let endpoint = "https://api.festeje.ai/api/";
if (process.env.NODE_ENV === "development")
  endpoint = "https://localhost:44393/api/";
  // endpoint = "https://api.festeje.ai/api/";

const http = axios.create({
  withCredentials: true,
  baseURL: endpoint,
});

http.interceptors.request.use(
  async (config) => {
    let accessToken;
    try {
      const user = JSON.parse(localStorage.user);
      accessToken = user?.accessToken;
    } catch (error) {
      console.log(error);
    }

    if (accessToken)
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      const accessToken = authentication.getUser()?.accessToken || "";
      if (!accessToken) return authentication.logout();
      return authentication.getRefreshedToken().then(() => {
        error.config.headers.Authorization = "Bearer " + accessToken;
        return http.request(error.config);
      });
    }

    return Promise.reject(error);
  }
);

export default http;
