<script setup>
	import { ref, inject } from "vue";

	import http from "@/http";

	import Card from "@/baseComponents/Card";

	import FileIcon from "vue-material-design-icons/FileClock";
	import ChevronLeft from "vue-material-design-icons/ChevronLeft";
	import ChevronRight from "vue-material-design-icons/ChevronRight";
	import ImageIcon from "vue-material-design-icons/ImageFilterHdr";

	import "swiper/css";
	import "swiper/css/bundle";
	import Swiper, { Navigation } from "swiper";
	Swiper.use([Navigation]);

	const swal = inject("$swal");
	const $formatDate = inject("$formatDate");

	const loading = ref(true);
	const quotes = ref([]);

	http
		.get(
			"event/product/list?page=1&take=10&orderBy=timestamp&statuses=Solicitado"
		)
		.then((response) => {
			quotes.value = response?.data?.list;
			if (quotes.value) startBudgetSlider();
		})
		.catch((err) => {
			console.log(err);
			fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
		})
		.finally(() => {
			loading.value = false;
		});

	const startBudgetSlider = () => {
		setTimeout(() => {
			const budgetSlider = new Swiper("#budgetSlider", {
				slidesPerView: "auto",
				spaceBetween: 35,
				freeMode: true,
				grabCursor: true,
				navigation: {
					nextEl: "#budgetNext",
					prevEl: "#budgetPrev",
				},
			});
		}, 150);
	};

	const fieldRequiredAlert = (msg) => {
		swal.fire({
			position: "center",
			icon: "warning",
			text: msg,
			showConfirmButton: true,
		});
	};
</script>

<template>
	<Card class="card-holder overflow-hidden">
		<div class="card-header">
			<div class="item mobile-hidden">
				<FileIcon :size="30" fillColor="var(--primary)" />
			</div>
			<span>Últimos orçamentos solicitados</span>
			<div v-show="quotes.length > 0 && !loading" class="controls">
				<div class="control" id="budgetPrev">
					<ChevronLeft :size="34" />
				</div>
				<div class="control" id="budgetNext">
					<ChevronRight :size="34" />
				</div>
			</div>
		</div>
		<div v-show="loading" class="flex flex-center mt-2 mb-2">
			<div class="loading"></div>
		</div>
		<span
			class="flex flex-center mt-2 mb-2"
			v-show="quotes.length <= 0 && !loading"
		>
			Nenhum orçamento pendente.
		</span>
		<div
			v-show="quotes.length > 0 && !loading"
			class="quotes-holder swiper-container"
			id="budgetSlider"
		>
			<div class="swiper-wrapper animated fadeIn">
				<div
					class="card-quote swiper-slide"
					v-for="quote in quotes"
					:key="quote.id"
				>
					<div class="quote-header">
						<div class="quote-img">
							<img
								v-if="quote.coverImage"
								:src="quote.coverImage"
								alt="Imagem orçamento"
							/>
							<ImageIcon v-else fillColor="#ccc" :size="40" />
						</div>
						<div class="header-info">
							<span class="title">{{ quote.name }}</span>
							<div class="dates">
								<div class="date-item">
									<span>Data do evento</span>
									<span class="text-center color-secondary">
										{{ $formatDate(quote.eventDate) }}
									</span>
								</div>
								<div class="date-item">
									<span>Solicitado em</span>
									<span class="text-center">{{ $formatDate(quote.timestamp) }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="divider"></div>
					<div class="quote-footer">
						<div class="status">
							<div class="ball"></div>
							<span>Orçamento pendente</span>
						</div>
						<router-link
							:to="`detalhes-orcamento/${quote.id}`"
							class="btn border details"
						>
							<span>Detalhes</span>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<router-link
			v-show="quotes.length > 1 && !loading"
			to="/orcamentos"
			class="btn"
		>
			<span>Ver todos</span>
		</router-link>
	</Card>
</template>

<style lang="scss" scoped>
	.overflow-hidden {
		overflow: hidden !important;
	}

	.controls {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2em;
		margin-left: auto;
		@media only screen and (max-width: 720px) {
			gap: 1.2em;
		}
		.control {
			position: relative;
			cursor: pointer;
			display: grid;
			place-items: center;
			padding: 0.1em;
			&.swiper-button-disabled {
				opacity: 0.4;
			}
		}
	}

	.quotes-holder {
		position: relative;
		width: 100%;
	}

	.divider {
		margin: 25px auto !important;
	}

	.card-quote {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 380px;
		padding: 1rem 1.2rem;
		border: 1px solid #dadada;
		border-radius: 10px;
		@media only screen and (max-width: 720px) {
			width: 100%;
		}
		.quote-header {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 20px;
			width: 100%;
			.quote-img {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #eee;
				width: 52px;
				height: 52px;
				border-radius: 4px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 4px;
				}
			}
			.header-info {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 80%;
				.title {
					font-size: 0.9rem;
					width: 100%;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.dates {
					position: relative;
					display: flex;
					width: 100%;
					justify-content: space-between;
					margin-top: 8px;
					.date-item {
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						span:first-child {
							font-size: 0.75rem;
							font-family: fontLight;
						}
						span {
							font-size: 0.75rem;
						}
					}
				}
			}
		}
		.quote-footer {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.status {
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: fit-content;
				gap: 15px;
				.ball {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: #fca842;
				}
				span {
					font-size: 0.8rem;
					color: #f48806;
				}
			}
			.btn {
				margin: 0;
				padding: 12px 15px;
				border: 1px solid var(--primary);
				span {
					font-size: 0.8rem;
				}
			}
		}
	}
</style>
