<script setup>
import { ref, inject } from "vue";

import http from "@/http";

import Header from "@/components/Header";
import ProductServiceSlider from "@/components/ProductServiceSlider";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";

import BriefcaseIcon from "vue-material-design-icons/Briefcase";
import ChevronDownIcon from "vue-material-design-icons/ChevronDown";
import SearchIcon from "vue-material-design-icons/Magnify";
import EditIcon from "vue-material-design-icons/Pencil";
import DeleteIcon from "vue-material-design-icons/TrashCan";
import EyeOutlineIcon from "vue-material-design-icons/EyeOutline";

const swal = inject("$swal");
const $formatDate = inject("$formatDate");
const categories = ref([]);
const selectedCategory = ref("");
const loading = ref(false);
const products = ref([]);
const searchParams = ref({
  params: {
    page: 1,
    take: 20,
    categoryId: "",
    search: "",
    type: "",
    orderBy: "-name",
  },
});
const allItemsFetched = ref(false);
const searchString = ref("");
const showSlider = ref(false);

http
  .get("/category/list")
  .then((response) => {
    categories.value = response?.data?.list;
  })
  .catch((err) => {
    console.log(err);
  });

const filterSearch = (filterType) => {
  if (
    filterType === "text" &&
    searchString.value === searchParams.value.params.search
  )
    return;

  searchParams.value.params.categoryId = selectedCategory.value;
  searchParams.value.params.search = searchString.value;
  searchParams.value.params.page = 1;
  products.value = [];
  allItemsFetched.value = false;
  getProducts();
};

const getProducts = () => {
  loading.value = true;

  http
    .get("/product/contractorList", searchParams.value)
    .then((response) => {
      products.value = [...products.value, ...response?.data?.list];
      if (products.value.length < 1) showSlider.value = true;
      if (response.data?.pages > searchParams.value.params.page) {
        searchParams.value.params.page++;
      } else {
        allItemsFetched.value = true;
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

getProducts();

const setType = (type) => {
  if (searchParams.value.params.type === type) {
    searchParams.value.params.type = "";
  } else {
    searchParams.value.params.type = type;
  }
  filterSearch("type");
};

const deleteProduct = (product, index) => {
  swal
    .fire({
      icon: "error",
      title: "Excluir serviço/produto",
      text: `Tem certeza que deseja excluir "${product.name}"? Esta ação NÃO poderá ser desfeita.`,
      confirmButtonText: "Excluir",
      confirmButtonColor: "var(--red1)",
      showCancelButton: true,
    })
    .then((response) => {
      if (response.isConfirmed) {
        http
          .delete(`product/?id=${product.id}`)
          .then((response) => {
            products.value.splice(index, 1);
            // sucessAlert();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
};

const closeSlider = () => {
  showSlider.value = false;
};

// const sucessAlert = () => {
//   swal.fire({
//     toast: true,
//     icon: "success",
//     title: "Dados atualizados com sucesso!",
//     position: "top-right",
//     showConfirmButton: false,
//     timer: 3500,
//     timerProgressBar: true,
//   });
// };
</script>

<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div v-show="showSlider" @click="closeSlider" class="bg-cover"></div>
  </transition>
  <ProductServiceSlider :showSlider="showSlider" @close="closeSlider" />
  <Header />
  <Page>
    <Card class="animated zoomIn">
      <div class="card-holder">
        <div class="card-header">
          <div class="title">
            <div class="item">
              <BriefcaseIcon :size="30" fillColor="var(--primary)" />
            </div>
            <span>Meus produtos & serviços</span>
          </div>
          <div class="search">
            <div class="itens-search">
              <div
                @click="setType('Produto')"
                class="item"
                :class="{ active: searchParams.params.type === 'Produto' }"
              >
                Produtos
              </div>
              <div
                @click="setType('Serviço')"
                class="item"
                :class="{ active: searchParams.params.type === 'Serviço' }"
              >
                Serviços
              </div>
            </div>
            <div class="input-searchs">
              <div class="input-item">
                <span>Categoria</span>
                <div class="input-holder">
                  <select
                    @change="filterSearch('category')"
                    v-model="selectedCategory"
                    name="category"
                  >
                    <option value="">Todas</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <div class="unit-holder">
                    <ChevronDownIcon fillColor="#959595" />
                  </div>
                </div>
              </div>
              <div class="input-item">
                <span>Buscar</span>
                <div class="input-holder">
                  <input
                    v-model="searchString"
                    type="text"
                    @keypress.enter="filterSearch('text')"
                  />
                  <div
                    @click="filterSearch('text')"
                    class="unit-holder search-btn"
                  >
                    <SearchIcon fillColor="#959595" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="products.length > 0" class="table-holder">
          <table style="min-width: 640px">
            <thead>
              <tr>
                <th>Nome e resumo</th>
                <th>Tipo</th>
                <th>A partir de</th>
                <th>Cadastrado em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in products" :key="product.id">
                <td>
                  <div class="description-holder">
                    <div class="flex flex-start">
                      {{ product.name }}
                    </div>
                    <div class="description-item">
                      {{ product.description }}
                    </div>
                  </div>
                </td>
                <td>
                  {{ product.type }}
                </td>
                <td>
                  {{ $currency(product.price) }}
                </td>
                <td>
                  {{ $formatDate(product.timestamp) }}
                </td>
                <td>
                  <div class="table-action-holder">
                    <a
                      :href="`https://app.festeje.ai/#/produto/${product.id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualizar página"
                    >
                      <EyeOutlineIcon fillColor="var(--primary)" />
                    </a>
                    <router-link
                      :to="`/produtos-servicos/${product.id}`"
                      title="Editar"
                    >
                      <EditIcon fillColor="var(--primary)" />
                    </router-link>
                    <div title="Excluir">
                      <DeleteIcon
                        @click="deleteProduct(product, index)"
                        fillColor="var(--red1)"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-show="loading" class="loading-holder flex flex-center mt-3 mb-3">
          <div class="loading"></div>
        </div>

        <div class="text-center" v-show="products.length < 1 && !loading">
          <span>Você ainda não adicionou nenhum produto ou serviço.</span>
        </div>

        <div v-show="!loading" class="btns-holder mt-3">
          <div
            v-show="products.length > 0 && !allItemsFetched"
            @click="getProducts"
            class="btn"
          >
            <span>Ver mais</span>
          </div>

          <router-link to="/produtos-servicos/novo" class="btn end">
            <span>Inserir novo</span>
          </router-link>
        </div>
      </div>
    </Card>
  </Page>
</template>

<style lang="scss" scoped>
.card-header {
  justify-content: space-between;
  @media only screen and (max-width: 1159px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.title {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  .item {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 0;
    width: 44px;
    height: 44px;
    border-radius: 14px;
    background-color: var(--secondary);
    span {
      transform: translate3d(8px, 8px, 0) scale(1.6);
    }
  }
}
.search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 2.6rem;
  @media only screen and (min-width: 721px) and (max-width: 1159px) {
    justify-content: space-between;
    width: 100%;
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1.2rem;
  }
  .item {
    position: relative;
    width: fit-content;
    background-color: transparent;
    border: 1px solid #cecece;
    border-radius: 6px;
    padding: 1.2rem 1.1rem;
    color: #959595;
    cursor: pointer;
    &.active {
      border-color: var(--secondary);
      color: var(--secondary);
    }
  }
  .itens-search {
    position: relative;
    display: flex;
    gap: 2.6rem;
    @media only screen and (max-width: 720px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  .input-searchs {
    position: relative;
    display: flex;
    gap: 2.6rem;
    @media only screen and (max-width: 720px) {
      width: 100%;
      flex-direction: column;
      gap: 1.2rem;
    }
  }
  .input-item,
  .input-holder {
    margin: 0;
    background-color: transparent;
    select {
      font-size: 0.9rem;
    }
    span {
      font-size: 0.9rem;
      color: #959595;
    }
    input,
    select {
      background-color: transparent;
      border: 1px solid #cecece;
    }
  }
}

.search-btn {
  pointer-events: initial;
  cursor: pointer;
}

.description-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  .description-item {
    position: relative;
    width: 80%;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 0.8rem;
    font-family: fontLight;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.btns-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    margin: 0;
  }
  .end {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: var(--primary);
    span {
      color: #f1f1f1;
    }
  }
  @media only screen and (max-width: 720px) {
    justify-content: space-between;
    .end {
      position: relative;
    }
  }
}
</style>
