<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import http from "@/http";
import authentication from "@/util/authentication";
import validators from "@/util/validators";
import ChevronLeftIcon from "vue-material-design-icons/ArrowLeftThinCircleOutline";

const router = useRouter();
const swal = inject("$swal");
const loading = ref(false);
const validEmail = ref(false);
const validConfirmationCode = ref(false);

const user = ref({
  email: "",
  code: null,
  newPassword: "",
  password2: "",
});
const userLogin = ref({
  email: "",
  password: "",
});

const checkEmail = () => {
  if (!validators.validateEmail(user.value.email)) {
    fieldRequiredAlert(
      "Por favor, verifique se o e-mail digitado está correto."
    );
    return;
  }

  loading.value = true;

  http
    .post(`contractor/forgotPassword?email=${user.value.email}`, {})
    .then(() => {
      validEmail.value = true;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        fieldRequiredAlert(
          "Não encontramos nenhum cadastro com o e-mail informado em nosso sistema."
        );
      } else {
        fieldRequiredAlert(response.data);
        console.log(err);
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const checkConfirmationCod = () => {
  loading.value = true;

  http
    .post("contractor/forgotPassword/Validate", user.value)
    .then(() => {
      validConfirmationCode.value = true;
    })
    .catch((error) => {
      fieldRequiredAlert(
        "Parece que o código informado está incorreto. Verifique e tente novamente."
      );
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const changePassword = () => {
  if (!user.value.newPassword) {
    fieldRequiredAlert("Por favor, informe sua nova senha.");
    return;
  }

  if (!user.value.password2) {
    fieldRequiredAlert("Por favor, confirme a sua nova senha.");
    return;
  }

  if (user.value.newPassword != user.value.password2) {
    fieldRequiredAlert(
      "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente."
    );
    return;
  }

  loading.value = true;

  http
    .post("contractor/resetPassword", user.value)
    .then(() => {
      userLogin.value.email = user.value.email;
      userLogin.value.password = user.value.newPassword;
      login();
    })
    .catch((error) => {
      console.log(error);
    });
};

const login = () => {
  loading.value = true;

  authentication
    .login(userLogin.value)
    .then(() => {
      // sucessAlert();
      router.push({ path: "/home" });
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert(
        "Algo deu errado, caso o erro persista entre em contato com o suporte."
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

// const sucessAlert = () => {
//   swal.fire({
//     toast: true,
//     icon: "success",
//     title: "Senha alterada com sucesso!",
//     position: "top-right",
//     showConfirmButton: false,
//     timer: 3500,
//     timerProgressBar: true,
//   });
// };

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: msg,
    showConfirmButton: true,
  });
};
</script>
<template>
  <div class="holder">
    <img
      src="../assets/images/balls1.png"
      alt="Imagem bolas"
      class="header-balls"
    />
    <div class="item-holder" v-if="!validEmail">
      <div class="title-item animated zoomIn" style="animation-delay: 200ms">
        Resgatar minha senha
      </div>
      <div class="input-item animated zoomIn" style="animation-delay: 300ms">
        <span>Nos informe seu e-mail</span>
        <input v-model="user.email" type="email" @keypress.enter="checkEmail" />
      </div>
      <div
        @click="checkEmail"
        class="btn desktop5 tablet6 animated zoomIn"
        style="animation-delay: 400ms"
      >
        <span v-show="!loading">Avançar</span>
        <div v-show="loading" class="loading white"></div>
      </div>
    </div>

    <div class="item-holder" v-show="validEmail && !validConfirmationCode">
      <div
        class="title-item text-center-mobile animated zoomIn"
        style="animation-delay: 200ms"
      >
        Enviamos um código para o seu e-mail
      </div>
      <div class="input-item animated zoomIn" style="animation-delay: 300ms">
        <span>Insira o código aqui</span>
        <input
          v-model="user.code"
          type="text"
          @keypress.enter="checkConfirmationCod"
        />
      </div>
      <div
        @click="checkConfirmationCod"
        class="btn desktop5 tablet6 animated zoomIn"
        style="animation-delay: 400ms"
      >
        <span v-show="!loading">Avançar</span>
        <div v-show="loading" class="loading white"></div>
      </div>
    </div>

    <div class="item-holder" v-show="validConfirmationCode">
      <div class="title-item animated zoomIn" style="animation-delay: 200ms">
        Redefinir minha senha
      </div>
      <div class="input-item animated zoomIn" style="animation-delay: 300ms">
        <span>Nova senha</span>
        <input v-model="user.newPassword" type="password" />
      </div>
      <div class="input-item animated zoomIn" style="animation-delay: 400ms">
        <span>Confirme a sua nova senha</span>
        <input
          v-model="user.password2"
          type="password"
          @keypress.enter="changePassword"
        />
      </div>
      <div class="flex">
        <div
          @click="changePassword"
          class="btn animated zoomIn"
          style="animation-delay: 500ms"
        >
          <span v-show="!loading">Atualizar senha</span>
          <div v-show="loading" class="loading white"></div>
        </div>
      </div>
    </div>

    <router-link to="/login" class="flex-center pointer mt-2">
      <ChevronLeftIcon :size="42" fillColor="var(--secondary)" />
    </router-link>

    <img
      src="../assets/images/balls2.png"
      alt="Imagem bolas"
      class="footer-balls"
    />
  </div>
</template>

<style lang="scss" scoped>
.holder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.item-holder {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  box-shadow: var(--box-shadow-default);
  padding: 2rem 3rem;
  border-radius: 12px;
  @media only screen and (max-width: 720px) {
    width: 80%;
    padding: 0;
    border: none;
    box-shadow: none;
  }
  @media only screen and (min-width: 721px) and (max-width: 1159px) {
    width: 60%;
  }
  .title-item {
    color: #000;
    margin: 0 auto 20px auto;
    font-size: 1.1rem;
    font-family: fontBold;
  }
  .input-item {
    span {
      font-family: fontRegular;
      color: #000;
      margin-bottom: 5px;
    }
    input {
      position: relative;
      font-family: fontLight;
      font-size: 1.1em;
      width: 100%;
      height: 46px;
      background-color: #eaeaea;
      border-radius: 8px;
      padding-left: 15px;
      border-bottom: none;
    }
  }
}

.header-balls {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  object-fit: cover;
  @media only screen and (max-width: 720px) {
    top: -40px;
  }
}
.footer-balls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  object-fit: cover;
  transform: scaleX(-1);
}

@media only screen and (max-width: 720px) {
  .text-center-mobile {
    text-align: center;
  }
}
</style>
