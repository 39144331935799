import http from "@/http";
import router from "@/router";

export default {
  login(objLogin) {
    return new Promise(function (resolve, reject) {
      http
        .post("contractor/login", objLogin)
        .then((response) => {
          if (response.data.authenticated) {
            localStorage.user = JSON.stringify(response.data);
            window?.cordova?.plugins?.CookieManagementPlugin?.flush();
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout() {
    localStorage.removeItem("user");
    router.push("/login");
  },
  isLoggedIn() {
    if (!localStorage.user) return false;
    const user = JSON.parse(localStorage.user);
    if (!user.accessToken) return false;

    return true;
  },
  getUser() {
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      return user;
    } else {
      return null;
    }
  },
  updateUserInfo(userInfo) {
    const user = JSON.parse(localStorage.user);
    const patientId = user.info.activePatientId;
    user.info = userInfo;
    user.info.activePatientId = patientId;
    localStorage.user = JSON.stringify(user);
  },
  getUserType() {
    if (localStorage.user) {
      try {
        const user = JSON.parse(localStorage.user);
        return user?.info?.type;
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
  },
  // getHeaders(contentType) {
  //   return {
  //     headers: {
  //       Authorization: "Bearer " + this.getUser().accessToken,
  //       "Content-Type": contentType ? contentType : "application/json",
  //     },
  //   };
  // },
  async getRefreshedToken() {
    const user = localStorage.user ? JSON.parse(localStorage?.user) : null;
    // if(sessionStorage.refreshingToken) {
    //   console.log(JSON.parse(sessionStorage.refreshingCall));
    //   return JSON.parse(sessionStorage.refreshingCall);
    // }
    sessionStorage.refreshingToken = true;
    const refreshingCall = http
      .post(`contractor/refresh?token=${user.accessToken}`, "")
      .then((response) => {
        if (response?.data?.accessToken) {
          user.accessToken = response.data.accessToken;
          user.expiration = response.data.expiration;
          localStorage.user = JSON.stringify(user);
          window?.cordova?.plugins?.CookieManagementPlugin?.flush();
          return Promise.resolve(true);
        }
      })
      .catch((err) => {
        console.log(err);
        this.logout();
      })
      .finally(() => {
        sessionStorage.removeItem("refreshingToken");
      });

    sessionStorage.refreshingCall = JSON.stringify(refreshingCall);
    return refreshingCall;
  },
};
