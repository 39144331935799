<script setup>
import { ref } from "vue";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";

import environment from "@/assets/videos/environment.mp4";
import angle from "@/assets/videos/angle.mp4";
import cellphone from "@/assets/videos/cellphone.mp4";
import product from "@/assets/videos/product.mp4";

import environmentPoster from "@/assets/images/environment.png";
import anglePoster from "@/assets/images/angle.png";
import cellphonePoster from "@/assets/images/cellphone.png";
import productPoster from "@/assets/images/product.png";

import LightBulbIcon from "vue-material-design-icons/LightbulbOn";

const loading = ref(false);
const videoList = [
  {
    id: "media1",
    path: environment,
    posterPath: environmentPoster,
    title: "Precisa trazer mais clientes para o seu espaço?",
    description:
      "Nesse vídeo, listamos algumas dicas sobre como utilizar a fotografia para criar uma experiência de imersão e expectativa nos usuários para instigá-los a agendar uma visita em seu negócio. ",
  },
  {
    id: "media2",
    path: angle,
    posterPath: anglePoster,
    title:
      "Qual a melhor perspectiva fotográfica para que os meus produtos tenham mais destaque?",
    description:
      " Aprenda dicas de enquadramento e acentue formas, texturas e detalhes do seu produto,	utilizando diferentes pontos de vista para criar composições visualmente impactantes. ",
  },
  {
    id: "media3",
    path: cellphone,
    posterPath: cellphonePoster,
    title: "Faça fotos incríveis gastando pouco",
    description:
      " Que tal elevar a qualidade visual das suas imagens e impulsionar as vendas? Confira nossas dicas para tirar fotos sensacionais utilizando apenas a câmera do seu smartphone.",
  },
  {
    id: "media4",
    path: product,
    posterPath: productPoster,
    title:
      "Transforme suas habilidades fotográficas e aumente a captação de clientes",
    description:
      " Descubra como aplicar truques simples de composição de imagens para gerar fotos irresistíveis dos seus produtos e serviços, utilizando materiais simples e muita criatividade. ",
  },
];
</script>

<template>
  <div>
    <Header />
    <Page>
      <Card class="card-holder overflow-hidden animated zoomIn">
        <div class="card-header">
          <div class="item mobile-hidden">
            <LightBulbIcon :size="30" fillColor="var(--primary)" />
          </div>
          <div class="title desktop6">
            <span><b>Como fotografar o meu produto?</b></span>
            <span class="desc">
              Acompanhe aqui vídeos com dicas e ideias que irão te auxiliar no
              processo de captação de fotos e vídeos de seus produtos e serviços
            </span>
          </div>
        </div>
        <div v-show="loading" class="flex flex-center mt-2 mb-2">
          <div class="loading"></div>
        </div>
        <div class="video-container">
          <div class="video" v-for="video in videoList" :key="video.id">
            <video
              ref="video"
              controls
              :src="video.path"
              :poster="video.posterPath"
            ></video>
            <h1>
              {{ video.title }}
            </h1>
            <p>{{ video.description }}</p>
          </div>
        </div>
      </Card>
    </Page>
  </div>
</template>

<style lang="scss" scoped>
.card-header {
  align-items: flex-start;
  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    .desc {
      font-weight: 300;
      font-size: 0.9rem;
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;

  // @media (max-width: 48rem) {
  //   grid-template-columns: 1fr;
  //   gap: 10px;
  // }

  .video {
    position: relative;
    h1 {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    video {
      position: relative;
      width: 100%;
      aspect-ratio: 9/16;
    }
  }
}
</style>
