<script setup>
import { ref, computed, onMounted } from "vue";

import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ChevronRight from "vue-material-design-icons/ChevronRight";

import "swiper/css";
import "swiper/css/bundle";
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

const sliderIndex = ref(0);

const props = defineProps({
  showSlider: {
    type: Boolean,
    default: false,
  },
});

const dynamicPositionModal = computed(() => {
  return window.innerWidth >= 1160 ? "desktop" : "mobile";
});

const dynamicEnterActive = computed(() => {
  return window.innerWidth >= 1160 ? "fadeIn" : "fadeInUp";
});

const dynamicLeaveActive = computed(() => {
  return window.innerWidth >= 1160 ? "fadeOut" : "fadeOutDown";
});

const startSlider = () => {
  setTimeout(() => {
    const slider = new Swiper("#slider", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      navigation: {
        nextEl: "#sliderNext",
        prevEl: "#sliderPrev",
      },
      pagination: {
        el: "#sliderPagination",
        type: "bullets",
        clickable: true,
      },
      on: {
        slideChange: function() {
          sliderIndex.value = this.realIndex;
        }
      }
    });
  }, 150);
};

onMounted(() => {
  startSlider();
});

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>
<template>
  <transition
    :enter-active-class="`animated ${dynamicEnterActive}`"
    :leave-active-class="`animated ${dynamicLeaveActive}`"
  >
    <div v-show="props.showSlider" :class="`holder ${dynamicPositionModal}`">
      <div class="item">
        <div class="swiper-container" id="slider">
          <span @click="close" class="close">X</span>
          <div class="swiper-wrapper">
            <div class="swiper-slide content">
              <img src="../assets/images/slider/001.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/002.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/003.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/004.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/005.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/006.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/007.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/008.jpg" alt="" />
            </div>
            <div class="swiper-slide content">
              <img src="../assets/images/slider/009.jpg" alt="" />
            </div>
          </div>
          <div id="sliderPagination" class="swiper-pagination"></div>
        </div>
      </div>
      <div class="controls">
        <div class="btn icon border control-left" id="sliderPrev">
          <ChevronLeft />
          <span>Voltar</span>
        </div>
        <div class="btn icon control" id="sliderNext" v-show="sliderIndex < 8">
          <span>{{ sliderIndex === 0 ? "Começar" : "Próximo" }}</span>
          <ChevronRight />
        </div>
        <div class="btn icon control" v-show="sliderIndex >= 8" @click="close">
          <span>Finalizar</span>
          <ChevronRight />
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.holder {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1000px;
  max-height: 90vh;
  background-color: #fff;
  margin: 0 auto;
  z-index: 999;
  &.mobile {
    border-radius: 1em 1em 0 0;
  }
  &.desktop {
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1em 1em 1em 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .item {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    background: transparent;
  }
  .content {
    position: relative;
    width: 100%;
    margin-top: -1px;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1em 1em 0 0;
    }
  }
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 30px;
  margin-left: auto;
  border-radius: 100%;
  background-color: hsla(0, 0%, 93%, 0.7);
  display: grid;
  place-items: center;
  text-align: center;
  color: var(--primary);
  cursor: pointer;
  z-index: 99;
}
.swiper-pagination {
  position: relative;
  display: block !important;
  align-content: center;
  margin-top: 20px;
}
.controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  .btn {
    margin: 0;
  }
  .control-left {
    span:first-child {
      margin-right: 0px;
    }
    span:last-child {
      margin-left: 15px;
    }
  }
}
</style>
