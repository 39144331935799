<script setup>
import { ref, inject } from "vue";
import { vMaska } from "maska";
import cep from "cep-promise";

import http from "@/http";
import authentication from "@/util/authentication";
import validators from "@/util/validators";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";
import DeleteAccount from "@/components/profile/DeleteAccount";

import AccountIcon from "vue-material-design-icons/AccountEdit";

const swal = inject("$swal");
const loading = ref(true);
const saving = ref(false);
const deleting = ref(false);
const zipcodeSearching = ref(false);
const zipcodeError = ref("");
const showFullAddress = ref(false);

const user = ref({
  name: "",
  email: "",
  phoneNumber: "",
  document: "",
  zipcode: "",
  state: "",
  city: "",
  address: "",
  number: "",
  complement: "",
  numberAddress: "",
  socialMedia: "",
});

http
  .get(`contractor/details?id=${authentication.getUser().info.id}`)
  .then((response) => {
    user.value = response?.data;
    if (user.value.zipcode) showFullAddress.value = true;
  })
  .catch((err) => {
    console.log(err);
  })
  .finally(() => {
    loading.value = false;
  });

const searchZipcode = () => {
  if (user.value.zipcode.length === 9) {
    const zip = user.value.zipcode.replace(/[^\d]/g, "");
    zipcodeSearching.value = true;
    zipcodeError.value = "";
    cep(zip)
      .then((response) => {
        user.value.state = response.state || "";
        user.value.city = response.city || "";
        user.value.neighborhood = response.neighborhood;
        user.value.address = response.street || "";
      })
      .catch((err) => {
        console.log(err);
        user.value.state = "";
        user.value.city = "";
        user.value.address = "";
        user.value.neighborhood = "";
        zipcodeError.value = "CEP não encontrado.";
      })
      .finally(() => {
        zipcodeSearching.value = false;
        showFullAddress.value = true;
      });
  }
};

const checkUser = () => {
  if (saving.value) return;

  if (!validators.validateFullName(user.value.name)) {
    fieldRequiredAlert("Por favor, insira seu nome completo.");
    return;
  }
  if (user.value.zipcode.length < 8) {
    fieldRequiredAlert("Por favor, insira seu CEP.");
    return;
  }
  if (!user.value.state) {
    fieldRequiredAlert("Por favor, insira seu estado.");
    return;
  }
  if (!user.value.city) {
    fieldRequiredAlert("Por favor, insira sua cidade.");
    return;
  }
  if (!user.value.address) {
    fieldRequiredAlert("Por favor, insira seu endereço.");
    return;
  }
  if (!user.value.phoneNumber) {
    fieldRequiredAlert("Por favor, insira seu telefone/celular.");
    return;
  }

  saving.value = true;
  updateUser();
};

const updateUser = () => {
  http
    .put("contractor", user.value)
    .then((response) => {
      if (response.status === 200) {
        // sucessAlert();
      }
    })
    .catch(() => {
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      saving.value = false;
    });
};

const deleteAccount = () => {
  deleting.value = true;
  http
    .delete("contractor?id=" + authentication.getUser().info.id)
    .then(() => {
      authentication.logout();
    })
    .catch(() => {
      fieldRequiredAlert(
        "Não foi possível excluir a conta. Por favor, tente novamente mais tarde."
      );
    })
    .finally(() => {
      deleting.value = false;
    });
};

// const sucessAlert = () => {
//   swal.fire({
//     toast: true,
//     icon: "success",
//     title: "Dados atualizados com sucesso!",
//     position: "top-right",
//     showConfirmButton: false,
//     timer: 3500,
//     timerProgressBar: true,
//   });
// };

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <Header />
  <Page>
    <Card class="animated zoomIn">
      <div class="card-holder">
        <div class="card-header">
          <div class="item">
            <AccountIcon :size="30" fillColor="var(--primary)" />
          </div>
          <span>Meus dados</span>
        </div>
        <div v-show="loading" class="flex flex-center mt-2 mb-2">
          <div class="loading"></div>
        </div>

        <div v-show="!loading" class="form">
          <div class="input-item">
            <span>Nome completo *</span>
            <input type="text" v-model="user.name" />
          </div>
          <div class="input-item">
            <span>E-mail *</span>
            <input disabled type="text" v-model="user.email" />
          </div>
          <div class="input-item">
            <span>CPF/CNPJ *</span>
            <input
              disabled
              v-maska
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              type="text"
              v-model="user.document"
            />
          </div>
          <div class="input-item">
            <span>Telefone/celular *</span>
            <input
              v-maska
              data-maska="['(##) ####-####', '(##) #####-####']"
              type="text"
              v-model="user.phoneNumber"
            />
          </div>
          <div class="input-item">
            <span>Perfil Instagram</span>
            <input type="text" v-model="user.socialMedia" />
            <span class="desc">* Insira o link do seu perfil.</span>
          </div>
          <div @input="searchZipcode" class="input-item">
            <span>CEP *</span>
            <input
              v-maska
              data-maska="#####-###"
              type="text"
              v-model="user.zipcode"
            />
            <span v-show="zipcodeError" class="input-warning color-red1">
              {{ zipcodeError }}
            </span>
          </div>
          <transition enter-active-class="animated fadeIn">
            <div v-show="zipcodeSearching" class="flex-center mt-2">
              <div class="loading black"></div>
            </div>
          </transition>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>UF *</span>
            <input type="text" v-model="user.state" />
          </div>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>Cidade *</span>
            <input type="text" v-model="user.city" />
          </div>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>Bairro *</span>
            <input type="text" v-model="user.neighborhood" />
          </div>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>Endereço *</span>
            <input type="text" v-model="user.address" />
          </div>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>Número</span>
            <input type="text" v-model="user.number" />
          </div>
          <div
            v-show="showFullAddress && !zipcodeSearching"
            class="input-item animated zoomIn"
          >
            <span>Complemento</span>
            <input type="text" v-model="user.complement" />
          </div>
        </div>

        <div v-show="!loading" class="flex flex-center">
          <div @click="checkUser" class="btn mt-3">
            <span v-show="!saving">Atualizar dados</span>
            <div v-show="saving" class="loading white"></div>
          </div>
        </div>
        <span v-show="!loading" class="text-center mt-1 desc">
          Ao se cadastrar na plataforma, você reconhece que leu, compreendeu e
          concorda com os
          <router-link to="/termos-de-uso" target="_blank">
            Termos de Uso.
          </router-link>
        </span>
      </div>
    </Card>

    <div v-show="!loading" class="divider"></div>

    <DeleteAccount
      v-if="!loading"
      class="animated fadeInUp"
      :style="`animation-delay: 400ms`"
      :deleting="deleting"
      @deleteAccount="deleteAccount"
    />
  </Page>
</template>

<style lang="scss" scoped>
.form {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 1.5rem;
  padding: 0 1.2rem;
  @media only screen and (min-width: 1160px) {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    padding: 0;
  }
  .input-item {
    margin: 0;
    span {
      font-family: fontRegular;
      color: var(--primary);
      margin-bottom: 5px;
    }
    input {
      position: relative;
      font-family: fontLight;
      font-size: 1.1em;
      width: 100%;
      height: 46px;
      background-color: #eaeaea;
      border-radius: 8px;
      padding-left: 15px;
      border-bottom: none;
    }
  }
}

.input-file {
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: pointer;
}

.desc {
  font-size: 0.8rem;
  margin: 0;
  a {
    font-size: 0.8rem;
    text-decoration: underline;
  }
}

.divider {
  height: 2px;
  margin: 55px auto;
}
</style>
