<script setup>
import { ref, defineEmits, computed, inject } from "vue";
import { vMaska } from "maska";
import cep from "cep-promise";

import PaymentIcon from "vue-material-design-icons/CreditCard.vue";

const emit = defineEmits(["paymentDone"]);
const swal = inject("$swal");

const props = defineProps({
  quotePrice: {
    type: Number,
    default: 0,
  },
  eventProductId: {
    type: String,
    default: "",
  },
  contractorEmail: {
    type: String,
    default: "",
  },
  updatingQuote: {
    type: Boolean,
    default: false,
  },
});

const creditCardStep = ref(1);
const creditCard = ref({
  number: "",
  name: "",
  month: "",
  year: "",
  cvc: "",
  flag: "",
  validate: "",
  identificationNumber: "",
});
const showFullAddress = ref(false);
const loading = ref(false);
const payment = ref({
  eventProductId: "",
  token: "",
  deviceId: "",
  payer: {
    name: "",
    email: "",
    document: "",
    phoneNumber: "",
    zipcode: "",
    neighborhood: "",
    address: "",
    number: "",
    complement: "",
    state: "",
    city: "",
  },
});
const mercadoPago = ref({});

const platformFee = computed(() => {
  return 5;
});

const quoteFee = computed(() => {
  return (props.quotePrice * 5) / 100;
});

const validateCardData = () => {
  creditCardStep.value = 2;
};

const getCep = () => {
  if (payment.value.payer.zipcode.length !== 9) {
    return;
  }
  cep(payment.value.payer.zipcode)
    .then((response) => {
      payment.value.payer.state = response.state;
      payment.value.payer.city = response.city;
      payment.value.payer.address = response.street;
      payment.value.payer.neighborhood = response.neighborhood;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      showFullAddress.value = true;
    });
};

const loadScript = (src) => {
  const script = document.createElement("script");
  script.src = src;
  document.querySelector("head").append(script);

  return script;
};

const startMPFields = () => {
  mercadoPago.value.fields
    .create("expirationDate", {
      placeholder: "",
    })
    .mount("form-checkout__expirationDate");
  mercadoPago.value.fields
    .create("securityCode", {
      placeholder: "",
    })
    .mount("form-checkout__securityCode");
  mercadoPago.value.fields
    .create("cardNumber", {
      placeholder: "",
    })
    .mount("form-checkout__cardNumber");
};

const loadScriptMP = () => {
  const script = loadScript("https://sdk.mercadopago.com/js/v2");
  script.onload = async () => {
    mercadoPago.value = new MercadoPago(
      "APP_USR-eb77a988-3a8d-43f6-919f-bb6333ad0e18"
    );
    startMPFields();
  };
  loadScript("https://www.mercadopago.com/v2/security.js");
};

loadScriptMP();

const getMPToken = async () => {
  if (loading.value) return;

  loading.value = true;
  try {
    var docNumber = payment.value.payer.document.replaceAll('.', '')
      .replaceAll('-', '').replaceAll('/', '');

    const token = await mercadoPago.value.fields.createCardToken({
      cardholderName: creditCard.value.name,
      identificationType: docNumber.length > 11 ? "CNPJ" : "CPF",
      identificationNumber: docNumber,
    });
    payment.value.eventProductId = props.eventProductId;
    payment.value.payer.name = creditCard.value.name;
    payment.value.payer.email = props.contractorEmail;
    payment.value.token = token?.id;
    payment.value.deviceId = MP_DEVICE_SESSION_ID;

    createPayment();
  } catch (error) {
    swal.fire({
      position: "center",
      icon: "warning",
      title: "Dados inválidos ou incompletos",
      text: "Os dados de pagamento informados não estão corretos, por favor, verifique todas as informações e tente novamente",
      showConfirmButton: true,
    });
    loading.value = false;
  }
};

const createPayment = () => {
  emit("createPayment", payment.value);
};

const validate = () => {
  getMPToken();
};
</script>
<template>
  <div class="holder">
    <div class="title-holder">
      <PaymentIcon fillColor="var(--primary)" :size="32" />
      <h1>Cobrança</h1>
    </div>
    <div class="obs">
      Para visualizar os dados de contato do cliente a plataforma cobra uma taxa
      de
      {{ platformFee }}% do valor do orçamento.
    </div>
    <div class="obs">
      Neste orçamento a taxa é de <b>{{ $currency(quoteFee) }}</b
      >.
    </div>

    <div class="payment-steps" :class="{ active: creditCardStep === 2 }">
      <div class="item active">Dados do cartão</div>
      <div class="item" :class="{ active: creditCardStep === 2 }">
        Dados de cobrança
      </div>
    </div>

    <transition enter-active-class="animated fadeInRight">
      <div v-show="creditCardStep === 1" class="card-fields-holder">
        <div class="card-input">
          <span>Número do cartão *</span>
          <div
            id="form-checkout__cardNumber"
            v-maska
            data-maska="#### #### #### ####"
            placeholder="1234 **** **** 5678"
            type="text"
            :v-model="creditCard.number"
          />
          <div class="flex flex-start transparent">
            <img
              src="@/assets/images/creditcard/mastercard.png"
              alt="master"
              class="flag"
            />
            <img
              src="@/assets/images/creditcard/visa.png"
              alt="visa"
              class="flag"
            />
            <img
              src="@/assets/images/creditcard/elo.png"
              alt="elo"
              class="flag"
            />
            <img
              src="@/assets/images/creditcard/amex.jpg"
              alt="amex"
              class="flag"
            />
            <img
              src="@/assets/images/creditcard/diners.png"
              alt="diners"
              class="flag"
            />
            <img
              src="@/assets/images/creditcard/hipercard.png"
              alt="hipercard"
              class="flag"
            />
          </div>
        </div>

        <div class="card-input">
          <span>Nome impresso no cartão *</span>
          <input id="cardName" type="text" v-model="creditCard.name" />
        </div>

        <div class="card-input">
          <span>CPF do títular do cartão *</span>
          <input
            id="identificationNumber"
            v-maska
            data-maska="['###.###.###-##', '##.###.###/####-##']"
            type="text"
            v-model="payment.payer.document"
          />
        </div>

        <div class="col-3">
          <div class="card-input">
            <span>Validade *</span>
            <div
              id="form-checkout__expirationDate"
              v-maska
              data-maska="##/##"
              placeholder="MM/AA"
              type="text"
              :v-model="creditCard.validate"
            />
          </div>
          <div class="card-input">
            <span>CVC *</span>
            <div
              id="form-checkout__securityCode"
              v-maska
              data-maska="['###', '####']"
              type="text"
              :v-model="creditCard.cvc"
            />
          </div>
          <select
            style="display: none"
            name="issuer"
            id="formCheckout__issuer"
          ></select>
          <select
            style="display: none"
            name="installments"
            id="formCheckout__installments"
          ></select>
          <input
            type="text"
            style="display: none"
            name="identificationType"
            id="identificationType"
          />
        </div>
      </div>
    </transition>

    <transition enter-active-class="animated fadeInLeft">
      <div v-show="creditCardStep === 2" class="card-fields-holder">
        <div class="col-2">
          <div class="card-input">
            <span>Telefone/celular *</span>
            <input
              v-maska
              data-maska="['(##) ####-####', '(##) #####-####']"
              type="text"
              v-model="payment.payer.phoneNumber"
            />
          </div>
          <div class="card-input">
            <span>CEP *</span>
            <input
              v-maska
              data-maska="#####-###"
              type="text"
              v-model="payment.payer.zipcode"
              @keyup="getCep"
            />
            <div @click="showFullAddress = !showFullAddress" class="cep-button">
              Não sei meu CEP
            </div>
          </div>
        </div>
        <div class="state-holder">
          <div v-show="showFullAddress" class="card-input animated fadeInLeft">
            <span>UF *</span>
            <input type="text" v-model="payment.payer.state" />
          </div>
          <div v-show="showFullAddress" class="card-input animated fadeInLeft">
            <span>Cidade *</span>
            <input type="text" v-model="payment.payer.city" />
          </div>
        </div>
        <div v-show="showFullAddress" class="card-input animated fadeInLeft">
          <span>Bairro *</span>
          <input type="text" v-model="payment.payer.neighborhood" />
        </div>
        <div v-show="showFullAddress" class="card-input animated fadeInLeft">
          <span>Endereço *</span>
          <input type="text" v-model="payment.payer.address" />
        </div>
        <div class="state-holder">
          <div v-show="showFullAddress" class="card-input animated fadeInLeft">
            <span>Número</span>
            <input type="text" v-model="payment.payer.number" />
          </div>
          <div v-show="showFullAddress" class="card-input animated fadeInLeft">
            <span>Complemento</span>
            <input type="text" v-model="payment.payer.complement" />
          </div>
        </div>
      </div>
    </transition>

    <div class="flex flex-between" style="margin-top: 30px">
      <div
        v-show="creditCardStep > 1"
        class="btn border ml-0 mr-0 mt-0"
        style="border-color: #999"
        @click="creditCardStep = 1"
      >
        <span style="color: #999">Voltar</span>
      </div>
      <div
        v-show="creditCardStep === 1"
        class="btn border ml-auto mr-0 mt-0"
        @click="validateCardData"
      >
        <span>Avançar</span>
      </div>
      <div
        v-show="creditCardStep === 2"
        class="btn ml-auto mr-0 mt-0"
        style="background: var(--primary)"
        @click="validate"
      >
        <span v-show="!props.updatingQuote" style="color: #f0f0f0"
          >Concluir pagamento</span
        >
        <div v-show="props.updatingQuote" class="loading white"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.holder {
  margin-top: -10px;
}
.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  h1 {
    position: relative;
    top: 2px;
  }
}

.obs {
  margin-top: 10px;
}

.payment-steps {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px auto 5px auto;
  pointer-events: none;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background: #ddd;
    z-index: 1;
    transition: width 1.25s ease-out;
  }
  &::after {
    width: 0;
    background: var(--primary);
  }
  &.active::after {
    width: 100%;
  }
  .item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    background-color: #fff;
    padding: 0 15px;
    color: #aaa;
    transition: color 0.4s ease;
    transition-delay: 0.6s;
    z-index: 2;
    &::before {
      position: relative;
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: #ccc;
      transition: background-color 0.4s ease;
      transition-delay: 0.6s;
    }
    &:first-child {
      padding: 0 15px 0 0;
    }
    &:last-child {
      padding: 0 0 0 15px;
    }
    &.active {
      color: var(--primary);
      &::before {
        background-color: var(--primary);
      }
    }
  }
}

.card-fields-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 30px;
  .col-2,
  .col-3 {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: flex-start;
  }
  .col-3 {
    grid-template-columns: 0.75fr 0.75fr 1fr;
    align-items: flex-end;
  }
  .card-input {
    position: relative;
    display: flex;
    flex-direction: column;
    input,
    select,
    div {
      position: relative;
      width: 100%;
      padding: 12px 16px;
      border-radius: 5px;
      background-color: #f1f1f1;
      margin-top: 5px;
      height: 42px;
      font-family: fontMedium;
      font-size: 1.05rem;
      color: var(--primary);
      letter-spacing: 2px;
      &::placeholder {
        font-family: fontRegular;
      }
    }
    .card-flag {
      position: absolute;
      right: 10px;
      max-width: 34px;
      max-height: 22px;
      top: 37px;
    }
    .flex-start {
      gap: 1rem;
      background-color: transparent;
      padding: 12px 0;
      .flag {
        position: relative;
        max-width: 34px;
        max-height: 20px;
      }
    }
  }
}

.cep-button {
  position: relative;
  padding: 8px 16px;
  color: #0036ff;
  text-align: right;
  cursor: pointer;
}

.state-holder {
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  gap: 1rem;
}
</style>
