<script setup>
import { ref, inject, onMounted } from "vue";
import { useRouter } from "vue-router";
import validators from "../util/validators";
import authentication from "../util/authentication";
import http from "@/http";

const router = useRouter();
const swal = inject("$swal");
const loading = ref(false);
const user = ref({ email: "", password: "" });
const banner = ref(null);

// if (authentication.isLoggedIn()) router.push({ path: "/home" });

onMounted(() => {
 const mobileScreen = window.innerWidth < 721;
 http
  .get("banner?page=painel-prestadores")
  .then((response) => {
   banner.value = mobileScreen
    ? response?.data[0]?.fileMobile
    : response?.data[0]?.file;
  })
  .catch((err) => {
   console.log(err);
   banner.value = require("@/assets/images/login.jpg");
  });
});

const login = () => {
 if (loading.value) return;

 if (!validators.validateEmail(user.value.email)) {
  fieldRequiredAlert("Por favor, verifique se o e-mail digitado está correto.");
  return;
 }

 if (user.value.password.length < 1) {
  fieldRequiredAlert("Por favor, informe sua senha.");
  return;
 }

 loading.value = true;

 authentication
  .login(user.value)
  .then((response) => {
   if (response === true) {
    router.push({ path: "/home" });
   }
  })
  .catch((err) => {
   console.log(err);
   if (err.response.status === 400) fieldRequiredAlert(err.response.data);
   else fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
  })
  .finally(() => {
   loading.value = false;
  });
};

const fieldRequiredAlert = (msg) => {
 swal.fire({
  position: "center",
  icon: "warning",
  title: "Ops...",
  text: msg,
  showConfirmButton: true,
 });
};
</script>
<template>
 <div class="all">
  <div class="img-holder animated fadeInLeft" style="animation-delay: 200ms">
   <transition enter-active-class="animated fadeIn">
    <img
     v-show="banner"
     :src="banner"
     alt="Imagem background"
     class="img-background"
    />
   </transition>
   <img
    src="../assets/images/logo/logo-white-yellow.png"
    alt="Imagem logo"
    class="img-logo mobile-hidden tablet-hidden"
   />
  </div>
  <div class="form-holder desktop6 tablet12 mobile12">
   <img
    src="../assets/images/logo/logo-white-yellow.png"
    alt="Imagem logo"
    class="img-logo desktop-hidden mb-2 animated zoomIn"
   />
   <span class="title-form animated fadeInDown" style="animation-delay: 500ms"
    >Já sou cadastrado</span
   >
   <div
    class="input-item mobile8 animated fadeIn"
    style="animation-delay: 550ms"
   >
    <span>E-mail</span>
    <input v-model="user.email" @keypress.enter="login" type="email" />
   </div>
   <div
    class="input-item mobile8 animated fadeIn"
    style="animation-delay: 600ms"
   >
    <span>Senha</span>
    <input v-model="user.password" @keypress.enter="login" type="password" />
   </div>
   <div
    @click="login"
    class="btn mobile8 animated fadeInUp"
    style="animation-delay: 700ms"
   >
    <span v-show="!loading">Entrar</span>
    <div v-show="loading" class="loading white"></div>
   </div>
   <div
    class="forgot-password mt-1 animated fadeInDown"
    style="animation-delay: 800ms"
   >
    <router-link to="/recuperar-senha" class="pointer">
     Esqueceu sua senha?
    </router-link>
   </div>
   <div
    class="register mt-4 mt-3-mobile mobile8 animated zoomIn"
    style="animation-delay: 900ms"
   >
    <span class="title-register">Ainda não possui uma conta?</span>
    <router-link to="/cadastro" class="btn border">
     <span>Cadastrar</span>
    </router-link>
   </div>
  </div>
 </div>
</template>
<style lang="scss" scoped>
.all {
 position: relative;
 width: 100vw;
 height: 100%;
 min-height: 100vh;
 display: flex;
 @media only screen and (max-width: 720px) {
  padding: 40px 0 30px 0;
 }
}

.img-holder {
 position: relative;
 display: flex;
 width: 50%;
 align-items: center;
 justify-content: center;
 background-color: #1f1f1f;
 .img-background {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
 }
 .img-logo {
  position: absolute;
  top: 10rem;
  width: 300px;
  object-fit: cover;
 }
 @media only screen and (max-width: 1159px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .img-background {
   filter: brightness(30%);
  }
 }
}

.form-holder {
 position: relative;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 50%;
 .img-logo {
  position: relative;
  width: 300px;
  object-fit: contain;
  @media only screen and (max-width: 720px) {
   width: 200px;
  }
 }
 .title-form {
  font-family: fontBold;
  font-size: 1.1rem;
  margin: 0 auto 20px auto;
  color: var(--primary);
  @media only screen and (max-width: 1159px) {
   color: #fff;
  }
 }
 .input-item {
  width: 50%;
  span {
   font-family: fontRegular;
   color: #868686;
   margin-bottom: 5px;
   @media only screen and (max-width: 1159px) {
    color: #c7c7c7;
   }
  }
  input {
   position: relative;
   font-family: fontLight;
   font-size: 1.1em;
   width: 100%;
   height: 46px;
   background-color: #eaeaea;
   border-radius: 8px;
   padding-left: 15px;
   border-bottom: none;
  }
 }
 .forgot-password {
  position: relative;
  text-align: center;
  a {
   font-size: 0.8rem;
   color: #8b8b8b;
   border-bottom: 1px solid #8b8b8b;
   font-family: fontBold;
   @media only screen and (max-width: 1159px) {
    color: #fff;
    border-bottom: 1px solid #fff;
    font-size: 0.9rem;
    font-family: fontRegular;
   }
  }
 }
 .btn {
  margin: 35px 0 0 0;
  width: 50%;
 }

 .register {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (max-width: 1159px) {
   .title-register,
   span {
    color: #fff !important;
   }
  }
  .title-register {
   color: #000;
   margin: 0 auto 10px auto;
   font-size: 0.9rem;
   font-family: fontLight;
  }
  .btn {
   margin: 0;
   width: 100%;
   &.border {
    border: 1px solid var(--secondary);
    span {
     color: var(--primary);
    }
   }
  }
 }
}
</style>
