<script setup>
	import { ref, inject } from "vue";

	import http from "@/http";

	import Header from "@/components/Header";
	import Page from "@/baseComponents/Page";
	import Card from "@/baseComponents/Card";

	import FileIcon from "vue-material-design-icons/File";
	import ImageIcon from "vue-material-design-icons/ImageFilterHdr";

	const swal = inject("$swal");
	const $formatDate = inject("$formatDate");

	const loading = ref(true);
	const loadingMore = ref(false);
	const quotes = ref([]);
	const selectedStatus = ref("Solicitado");
	const allBudgetsFetched = ref(false);
	const page = ref(1);

	const getQuotes = () => {
		if (loadingMore.value) return;
		if (page.value > 1) loadingMore.value = true;

		http
			.get(
				`event/product/list?page=${page.value}&take=20&orderBy=timestamp&statuses=${selectedStatus.value}`
			)
			.then((response) => {
				quotes.value = [...quotes.value, ...response?.data?.list];

				if (response.data?.pages > page.value) {
					page.value++;
				} else {
					allBudgetsFetched.value = true;
				}
			})
			.catch((err) => {
				console.log(err);
				fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
			})
			.finally(() => {
				loading.value = false;
				loadingMore.value = false;
			});
	};
	getQuotes();

	const filterQuotes = () => {
		allBudgetsFetched.value = false;
		page.value = 1;
		quotes.value = [];
		loading.value = true;
		getQuotes();
	};

	const statusColor = (quote) => {
		if (quote.status === "Solicitado") return "#f48806";
		if (quote.status === "Definido") return "#235798";
		if (quote.status === "Aprovado") return "#1FB42E";
		if (quote.status === "Reprovado") return "#e8215e";
		if (quote.status === "Cancelado") return "#a1a1a1";

		return "#555";
	};

	const friendlyStatus = (quote) => {
		if (quote.status === "Solicitado") return "Orçamento pendente";
		if (quote.status === "Definido") return "Aguardando cliente";
		if (quote.status === "Aprovado") return "Aprovado";
		if (quote.status === "Reprovado") return "Reprovado";
		if (quote.status === "Cancelado") return "Cancelado";

		return "--";
	};

	const fieldRequiredAlert = (msg) => {
		swal.fire({
			position: "center",
			icon: "warning",
			text: msg,
			showConfirmButton: true,
		});
	};
</script>

<template>
	<Header />
	<Page>
		<Card class="card-holder animated zoomIn">
			<div class="card-header">
				<div class="item mobile-hidden">
					<FileIcon :size="30" fillColor="var(--primary)" />
				</div>
				<span>Orçamentos solicitados</span>
				<div class="filter-holder ml-auto">
					<div class="input-item mt-0">
						<span>Exibindo</span>
						<select
							v-model="selectedStatus"
							name="quotesFilter"
							id="quotesFilter"
							@change="filterQuotes"
						>
							<option value="Solicitado">Somente pendentes</option>
							<option value="Definido">Somente orçados</option>
							<option value="Aprovado">Somente aprovados</option>
							<option value="Reprovado">Somente reprovados</option>
							<option value="Cancelado">Somente cancelados</option>
							<option value="">Tudo</option>
						</select>
					</div>
				</div>
			</div>
			<div v-show="loading" class="flex flex-center mt-2 mb-2">
				<div class="loading"></div>
			</div>
			<span
				class="flex flex-center mt-2 mb-2"
				v-show="quotes.length <= 0 && !loading"
			>
				Você ainda não possuí nenhum orçamento solicitado.
			</span>
			<div v-show="quotes.length > 0 && !loading" class="quotes-holder">
				<div
					class="card-quote swiper-slide"
					v-for="quote in quotes"
					:key="quote.id"
				>
					<div class="quote-header">
						<div class="quote-img">
							<img
								v-if="quote.coverImage"
								:src="quote.coverImage"
								alt="Imagem orçamento"
							/>
							<ImageIcon v-else fillColor="#ccc" :size="40" />
						</div>
						<div class="header-info">
							<span class="title">{{ quote.name }}</span>
							<div class="dates">
								<div class="date-item">
									<span>Data do evento</span>
									<span class="text-center color-primary">
										{{ $formatDate(quote.eventDate) }}
									</span>
								</div>
								<div class="date-item">
									<span>Solicitado em</span>
									<span class="text-center">{{ $formatDate(quote.timestamp) }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="divider"></div>
					<div
						class="quote-footer"
						:class="{ 'flex-wrap-mobile': quote.status == 'Aprovado' }"
					>
						<div class="status">
							<div class="ball" :style="`background:${statusColor(quote)}`"></div>
							<span :style="`color:${statusColor(quote)}`">{{
								friendlyStatus(quote)
							}}</span>
						</div>
						<router-link
							:to="`detalhes-orcamento/${quote.id}`"
							class="btn border details"
						>
							<span v-if="quote.status == 'Aprovado'"
								>Ver dados de contato do cliente</span
							>
							<span v-else>Detalhes</span>
						</router-link>
					</div>
				</div>
			</div>
			<div v-show="!allBudgetsFetched && !loading" @click="getQuotes" class="btn">
				<span v-show="!loadingMore">Carregar mais</span>
				<div v-show="loadingMore" class="loading white"></div>
			</div>
		</Card>
	</Page>
</template>

<style lang="scss" scoped>
	.quotes-holder {
		position: relative;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
		gap: 1.5rem;
		@media only screen and (max-width: 720px) {
			grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
		}
	}

	.divider {
		margin: 25px auto !important;
	}

	.card-quote {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 1rem 1.2rem;
		border: 1px solid #dadada;
		border-radius: 10px;

		.quote-header {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 20px;
			width: 100%;
			.quote-img {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #eee;
				width: 52px;
				height: 52px;
				border-radius: 4px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 4px;
				}
			}
			.header-info {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 80%;
				.title {
					font-size: 0.9rem;
					width: 100%;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.dates {
					position: relative;
					display: flex;
					width: 100%;
					justify-content: space-between;
					margin-top: 8px;
					.date-item {
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						span:first-child {
							font-size: 0.75rem;
							font-family: fontLight;
						}
						span {
							font-size: 0.75rem;
						}
					}
				}
			}
		}
		.quote-footer {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.status {
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: fit-content;
				gap: 15px;
				.ball {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: var(--secondary);
				}
				span {
					font-size: 0.8rem;
				}
			}
			.btn {
				margin: 0;
				padding: 12px 15px;
				border: 1px solid var(--primary);
				span {
					font-size: 0.8rem;
				}
			}
			@media only screen and (max-width: 720px) {
				&.flex-wrap-mobile {
					flex-wrap: wrap;
					gap: 10px;
				}
			}
		}
	}
</style>
