<script setup>
import { ref, inject, computed } from "vue";
import { useRouter } from "vue-router";
import { vMaska } from "maska";
import cep from "cep-promise";

import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";

import ArrowIcon from "vue-material-design-icons/ArrowLeftThin";
import CloseIcon from "vue-material-design-icons/Close";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight";

const router = useRouter();
const swal = inject("$swal");

const user = ref({
  name: "",
  email: "",
  phoneNumber: "",
  document: "",
  zipcode: "",
  state: "",
  city: "",
  address: "",
  number: "",
  complement: "",
  password: "",
  indicationCode: "",
  registerCode: "",
  socialMedia: "",
});

const loading = ref(false);
const zipcodeSearching = ref(false);
const showFullAddress = ref(false);
const zipcodeError = ref("");
const confirmPassword = ref("");
const showConfirmCode = ref(false);
const sending = ref(false);
const checkTerms = ref(true);

const searchZipcode = () => {
  console.log(user.value.zipcode);
  if (user.value.zipcode.length === 9) {
    const zip = user.value.zipcode.replace(/[^\d]/g, "");
    zipcodeSearching.value = true;
    zipcodeError.value = "";
    cep(zip)
      .then((response) => {
        user.value.state = response.state || "";
        user.value.city = response.city || "";
        user.value.neighborhood = response.neighborhood;
        user.value.address = response.street || "";
      })
      .catch((err) => {
        console.log(err);
        user.value.state = "";
        user.value.city = "";
        user.value.address = "";
        user.value.neighborhood = "";
        zipcodeError.value = "CEP não encontrado.";
      })
      .finally(() => {
        zipcodeSearching.value = false;
        showFullAddress.value = true;
      });
  }
};

const checkUser = () => {
  if (loading.value) return;
  loading.value = true;

  if (!checkTerms.value) {
    fieldRequiredAlert(
      "Por favor, aceite os termos para prosseguir com o cadastro."
    );
    loading.value = false;
    return;
  }

  if (!validators.validateFullName(user.value.name.trim())) {
    fieldRequiredAlert("Por favor, insira seu nome completo.");
    loading.value = false;
    return;
  }
  if (!validators.validateEmail(user.value.email)) {
    fieldRequiredAlert(
      "Por favor, verifique se o e-mail digitado está correto."
    );
    loading.value = false;
    return;
  }
  if (
    !validators.validateCNPJ(user.value.document) &&
    !validators.validateCPF(user.value.document)
  ) {
    fieldRequiredAlert(
      "Por favor, verifique se o CPF/CNPJ digitado está correto."
    );
    loading.value = false;
    return;
  }
  if (!user.value.phoneNumber) {
    fieldRequiredAlert("Por favor, insira seu telefone/celular.");
    loading.value = false;
    return;
  }
  if (user.value.zipcode.length < 8) {
    fieldRequiredAlert("Por favor, insira seu CEP.");
    loading.value = false;
    return;
  }
  if (!user.value.state) {
    fieldRequiredAlert("Por favor, insira seu estado.");
    loading.value = false;
    return;
  }
  if (!user.value.city) {
    fieldRequiredAlert("Por favor, insira sua cidade.");
    loading.value = false;
    return;
  }
  if (!user.value.address) {
    fieldRequiredAlert("Por favor, insira seu endereço.");
    loading.value = false;
    return;
  }
  if (!user.value.password) {
    fieldRequiredAlert("Por favor, insira uma senha.");
    loading.value = false;
    return;
  }
  if (!confirmPassword.value) {
    fieldRequiredAlert("Por favor, insira sua confirmação de senha.");
    loading.value = false;
    return;
  }
  if (user.value.password != confirmPassword.value) {
    fieldRequiredAlert(
      "Por favor, verifique se sua confirmação de senha está correta."
    );
    loading.value = false;
    return;
  }

  sendRegisterCode();
};

const sendRegisterCode = () => {
  const objToSend = { email: user.value.email, document: user.value.document };

  http
    .post("contractor/validateAndSendRegisterCode", objToSend)
    .then(() => {
      showConfirmCode.value = true;
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) fieldRequiredAlert(err.response.data);
      else fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      loading.value = false;
    });
};

const createUser = () => {
  sending.value = true;

  if (!user.value.registerCode) {
    fieldRequiredAlert(
      "Por favor, insirá o código que enviamos no seu e-mail de cadastro."
    );
    return;
  }

  http
    .post("contractor", user.value)
    .then((response) => {
      if (response.status === 200) {
        // sucessAlert();
        const userLogin = {
          email: user.value.email,
          password: user.value.password,
        };
        loginUser(userLogin);
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 400)
        fieldRequiredAlert(error.response.data);
      else fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
    })
    .finally(() => {
      sending.value = false;
    });
};

const loginUser = (userLogin) => {
  authentication
    .login(userLogin)
    .then((response) => {
      if (response === true) {
        router.push({ path: "/produtos-servicos" });
      }
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert(
        "Algo deu errado, caso o erro persista entre em contato com o suporte."
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const cancelCreateUser = () => {
  showConfirmCode.value = false;
  loading.value = false;
  user.value.registerCode = "";
};

const dynamicPositionModal = computed(() => {
  return window.innerWidth >= 1160 ? "desktop" : "mobile";
});
const dynamicEnterActive = computed(() => {
  return window.innerWidth >= 1160 ? "fadeIn" : "fadeInUp";
});

// const sucessAlert = () => {
//   swal.fire({
//     toast: true,
//     icon: "success",
//     title: "Conta criada com sucesso!",
//     position: "top-right",
//     showConfirmButton: false,
//     timer: 3500,
//     timerProgressBar: true,
//   });
// };

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
</script>
<template>
  <div class="all">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showConfirmCode" class="bg-cover"></div>
    </transition>
    <div
      v-show="showConfirmCode"
      :class="`holder-modal ${dynamicPositionModal} animated ${dynamicEnterActive}`"
    >
      <div class="title-holder">
        <h1>Enviamos um código para autenticar seu e-mail!</h1>
      </div>
      <div class="input-item mt-2">
        <span>Insira o código aqui *</span>
        <input type="text" v-model="user.registerCode" />
      </div>
      <div class="btns-holder mt-3 mt-2-mobile">
        <div @click="cancelCreateUser" class="btn border icon cancel">
          <span>Cancelar</span>
          <CloseIcon :size="20" fillColor="var(--red1)" />
        </div>
        <div @click="createUser" class="btn border icon send">
          <span v-show="!sending">Enviar</span>
          <ChevronRightIcon
            :size="26"
            v-show="!sending"
            fillColor="var(--green1)"
          />
          <div v-show="sending" class="loading green"></div>
        </div>
      </div>
    </div>
    <div class="img-holder tablet-hidden mobile-hidden animated fadeInLeft">
      <img
        src="../assets/images/register.jpg"
        alt="Imagem logo"
        class="img-background"
      />
      <img
        src="../assets/images/logo/logo-white-yellow.png"
        alt="Imagem logo"
        class="img-logo"
      />
    </div>
    <div class="form-holder">
      <img
        src="../assets/images/balls1.png"
        alt="Imagem bolas"
        class="header-balls"
      />
      <img
        src="../assets/images/logo/logo-black-yellow.png"
        alt="Imagem logo"
        class="img-logo desktop-hidden mb-2"
      />
      <div
        class="form-header animated fadeInDown"
        style="animation-delay: 200ms"
      >
        <router-link to="/login">
          <ArrowIcon :size="30" fillColor="var(--primary)" />
        </router-link>
        <span class="title-form">Cadastro do prestador</span>
      </div>

      <div class="form">
        <div class="input-item animated zoomIn" style="animation-delay: 250ms">
          <span>Nome completo *</span>
          <input type="text" v-model="user.name" />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 300ms">
          <span>E-mail *</span>
          <input type="email" v-model="user.email" />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 350ms">
          <span>CPF/CNPJ *</span>
          <input
            v-maska
            data-maska="['###.###.###-##', '##.###.###/####-##']"
            type="text"
            v-model="user.document"
          />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 400ms">
          <span>Telefone/celular *</span>
          <input
            v-maska
            data-maska="['(##) ####-####', '(##) #####-####']"
            type="text"
            v-model="user.phoneNumber"
          />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 450ms">
          <span>Perfil Instagram</span>
          <input type="text" v-model="user.socialMedia" />
          <span class="desc">* Insira o link do seu perfil.</span>
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 500ms">
          <span>CEP *</span>
          <input
            @input="searchZipcode"
            v-maska
            data-maska="#####-###"
            type="text"
            v-model="user.zipcode"
          />
          <span v-show="zipcodeError" class="input-warning color-red1">
            {{ zipcodeError }}
          </span>
        </div>
        <transition enter-active-class="animated fadeIn">
          <div v-show="zipcodeSearching" class="flex-center mt-2">
            <div class="loading black"></div>
          </div>
        </transition>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>UF *</span>
          <input type="text" v-model="user.state" />
        </div>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>Cidade *</span>
          <input type="text" v-model="user.city" />
        </div>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>Bairro *</span>
          <input type="text" v-model="user.neighborhood" />
        </div>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>Endereço *</span>
          <input type="text" v-model="user.address" />
        </div>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>Número</span>
          <input type="text" v-model="user.number" />
        </div>
        <div
          v-show="showFullAddress && !zipcodeSearching"
          class="input-item animated zoomIn"
        >
          <span>Complemento</span>
          <input type="text" v-model="user.complement" />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 550ms">
          <span>Senha *</span>
          <input type="password" v-model="user.password" />
        </div>
        <div class="input-item animated zoomIn" style="animation-delay: 600ms">
          <span>Confirmar senha *</span>
          <input
            type="password"
            v-model="confirmPassword"
            @keypress.enter="checkUser"
          />
        </div>
      </div>

      <div class="form">
        <div class="divider"></div>
      </div>

      <div class="form">
        <div class="input-item animated zoomIn" style="animation-delay: 650ms">
          <span>Código de indicação</span>
          <input type="text" v-model="user.indicationCode" />
        </div>
      </div>

      <div class="flex flex-center mt-2">
        <div
          @click="checkUser"
          class="btn animated fadeInUp"
          style="animation-delay: 500ms"
        >
          <span v-show="!loading">Criar conta</span>
          <div v-show="loading" class="loading white"></div>
        </div>
      </div>
      <span class="text-center desc-terms desktop6 mobile12">
        Ao se cadastrar na plataforma, você reconhece que leu, compreendeu e
        concorda com os
        <router-link to="/termos-de-uso" target="_blank">
          Termos de Uso.
        </router-link>
      </span>
      <div class="flex-center mb-1">
        <div class="switch mr-1">
          <input
            class="pointer"
            v-model="checkTerms"
            type="checkbox"
            id="check"
          />
          <span class="slider round"></span>
        </div>
        <label class="pointer desc-terms" for="check" style="margin-top: 0">
          Eu li e aceito os Termos de Uso.
        </label>
      </div>
      <img
        src="../assets/images/balls2.png"
        alt="Imagem bolas"
        class="footer-balls mobile-hidden"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.all {
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  @media only screen and (max-width: 720px) {
    padding: 40px 0 30px 0;
  }
}

.img-holder {
  position: relative;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  .img-background {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
  }
  .img-logo {
    position: absolute;
    top: 10rem;
    width: 300px;
    object-fit: cover;
  }
}

.form-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1160px) {
    width: 50%;
  }
  .form-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
    width: 70%;
    .title-form {
      color: #000;
      margin: 0 auto;
      font-size: 1.1rem;
      font-family: fontBold;
    }
  }
  .form {
    position: relative;
    width: 70%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    .input-item {
      margin: 0;
      span {
        font-family: fontRegular;
        color: #868686;
        margin-bottom: 5px;
      }
      input {
        position: relative;
        font-family: fontLight;
        font-size: 1.1em;
        width: 100%;
        height: 46px;
        background-color: #eaeaea;
        border-radius: 8px;
        padding-left: 15px;
        border-bottom: none;
      }
    }
  }
  .img-logo {
    position: relative;
    width: 200px;
    object-fit: contain;
    margin-top: 40px;
  }
  .btn {
    margin: 0;
    margin-bottom: 20px;
    z-index: 2;
  }
  .header-balls {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    object-fit: cover;
    @media only screen and (max-width: 720px) {
      top: -40px;
    }
  }
  .footer-balls {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    object-fit: cover;
  }
}

.holder-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 620px;
  max-height: 100vh;
  padding: 2.5em 2em;
  background-color: #fff;
  margin: 0 auto;
  z-index: 999;
  overflow-y: auto;
  &.mobile {
    border-radius: 1em 1em 0 0;
  }
  &.desktop {
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1em 1em 1em 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .title-holder {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 30px;
    justify-content: center;
    align-items: center;
    margin: 0 0 25px 0;
    h1 {
      position: relative;
      text-align: center;
      font-size: 1.5em;
      color: var(--primary);
      left: 15px;
    }
  }
  .btns-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      margin: 0;
      &.cancel {
        border: 1px solid var(--red1);
        span {
          color: var(--red1);
        }
      }
      &.send {
        border: 1px solid var(--green1);
        span {
          color: var(--green1);
        }
      }
    }
  }
}

.desc {
  font-size: 0.9em;
  margin: 0;
  font-family: fontLight;
}

.desc-terms {
  font-size: 0.8rem;
  margin: 0;
  z-index: 2;
  a {
    font-size: 0.8rem;
    text-decoration: underline;
  }
}

.loading.green {
  border: 2px solid var(--green1);
  border-right-color: transparent;
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 22px;
}

.switch input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 8px;
  right: 5px;
  bottom: 2px;
  background-color: #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 1;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 5px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--green1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
