<script setup>
import { inject } from "vue";

import Card from "@/baseComponents/Card";
import DeleteIcon from "vue-material-design-icons/AccountRemoveOutline";
import CloseIcon from "vue-material-design-icons/Close";

const swal = inject("$swal");
const emit = defineEmits(["deleteAccount"]);

const props = defineProps({
  deleting: {
    type: Boolean,
    default: false,
  },
});

const deleteAccount = () => {
  swal
    .fire({
      icon: "error",
      title: "Tem certeza que deseja excluir sua conta?",
      html: "<b>Essa ação não poderá ser desfeita!</b>",
      confirmButtonText: "Sim, excluir",
      confirmButtonColor: "var(--red1)",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    })
    .then((result) => {
      if (result.value) {
        emit("deleteAccount");
      }
    });
};
</script>

<template>
  <Card>
    <div class="card-title-holder">
      <div class="card-title flex">
        <DeleteIcon :size="32" fillColor="var(--red1)" />
        <p class="color-red1 ml-1">Excluir conta</p>
      </div>
    </div>
    <p class="obs">
      Ao excluir sua conta você perderá imediatamente acesso à plataforma e a
      todos os dados, como seus produtos, serviços, perguntas dos usuários e
      orçamentos. Tem certeza que deseja prosseguir?
    </p>

    <div class="flex flex-end mt-2">
      <div @click="deleteAccount" class="btn icon ml-auto bg-red1 mt-0">
        <span v-show="!deleting" class="color-white">Excluir minha conta</span>
        <CloseIcon v-show="!deleting" fillColor="#fff" />
        <div v-show="deleting" class="loading white"></div>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.card-title-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img {
    position: relative;
    margin-top: -80px;
  }
}

.obs {
  margin-top: 10px;
  color: #999;
}

.btn {
  &.bg-red1 {
    background-color: var(--red1) !important;
  }
  .color-white {
    color: #fff;
  }
}
</style>
