<script setup>
import { ref, computed, watch, inject } from "vue";
import http from "@/http";
import QuestionIcon from "vue-material-design-icons/CommentQuestion";
import SendIcon from "vue-material-design-icons/Send";
import AccountIcon from "vue-material-design-icons/AccountCircle";
import ArrowIcon from "vue-material-design-icons/ArrowRightBottom";
import CheckIcon from "vue-material-design-icons/Check";
import WarningIcon from "vue-material-design-icons/AlertCircleOutline";

const $formatDate = inject("$formatDate");

const emit = defineEmits(["close", "lastMessageSent"]);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  eventId: {
    type: String,
    default: "",
  },
  productId: {
    type: String,
    default: "",
  },
  userId: {
    type: String,
    default: "",
  },
});

const searchParams = ref({
  params: {
    page: 1,
    take: 5,
    eventId: "",
    productId: "",
    userId: "",
    orderBy: "timestamp",
  },
});

const allMessagesFetched = ref(false);

const messages = ref([]);
const newMessage = ref("");
const loading = ref(false);

watch(
  () => props.show,
  (value) => {
    if (value) {
      messages.value = [];
      searchParams.value.params.eventId = props.eventId;
      searchParams.value.params.productId = props.productId;
      searchParams.value.params.userId = props.userId;
      searchParams.value.params.page = 1;
      allMessagesFetched.value = false;
      newMessage.value = "";
      getMessages();
    }
  }
);

const getMessages = () => {
  loading.value = true;
  http
    .get("message/list", searchParams.value)
    .then((response) => {
      const newMsgs = response.data?.list
        .map((msg) => {
          msg.sent = true;
          return msg;
        })
        .reverse();
      messages.value = [...newMsgs, ...messages.value];

      if (searchParams.value.params.page == 1) {
        scrollMessages();
      }

      if (response.data?.pages > searchParams.value.params.page) {
        searchParams.value.params.page++;
      } else {
        allMessagesFetched.value = true;
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const sendMessage = () => {
  if (!newMessage.value) return;
  const newMsg = {
    eventId: props.eventId,
    productId: props.productId,
    userId: props.userId,
    description: newMessage.value,
    contractorName: "",
    sent: false,
    from: "Fornecedor",
    timestamp: new Date(),
  };
  newMessage.value = "";
  messages.value.push(newMsg);
  scrollMessages();
  http.post("message", newMsg).then((response) => {
    const lastMsg = messages.value[messages.value.length - 1];
    lastMsg.timestamp = response.data?.timestamp;
    lastMsg.sent = true;
    lastMsg.contractorName = response.data?.contractorName;
    emit("lastMessageSent");
  });
};

const scrollMessages = () => {
  const messagesHolder = document.querySelector("#messagesHolder");
  setTimeout(() => {
    messagesHolder.scroll({
      top: messagesHolder.scrollHeight,
      behavior: "smooth",
    });
  }, 50);
};

const dynamicPositionModal = computed(() => {
  return window.innerWidth >= 1160 ? "desktop" : "mobile";
});
const dynamicEnterActive = computed(() => {
  return window.innerWidth >= 1160 ? "fadeIn" : "fadeInUp";
});
const dynamicLeaveActive = computed(() => {
  return window.innerWidth >= 1160 ? "fadeOut" : "fadeOutDown";
});

const close = () => {
  emit("close");
};
</script>
<template>
  <transition
    :enter-active-class="`animated ${dynamicEnterActive}`"
    :leave-active-class="`animated ${dynamicLeaveActive}`"
  >
    <div v-show="props.show" :class="`holder ${dynamicPositionModal}`">
      <div class="title-holder">
        <div class="icon-holder">
          <QuestionIcon fillColor="var(--primary)" />
        </div>
        <h1>Mensagens trocadas</h1>
        <span @click="close" class="close">X</span>
      </div>

      <div id="messagesHolder" class="messages-holder">
        <div v-show="loading" class="flex flex-center mt-3">
          <div class="loading"></div>
        </div>
        <div v-show="!loading && !allMessagesFetched" class="flex flex-center">
          <div @click="getMessages" class="btn-older-msgs pointer">
            Ver mensagens mais antigas
          </div>
        </div>
        <div
          v-for="(msg, index) in messages"
          :key="`msg${index}`"
          class="message animated zoomIn"
          :class="{
            response: msg.from === 'Fornecedor',
            blocked: msg.status === 'Bloqueada',
          }"
        >
          <CheckIcon
            v-if="msg.sent"
            class="check-icon animated zoomIn"
            fillColor="var(--green1)"
            :size="18"
          />
          <div class="header">
            <AccountIcon
              :fillColor="msg.from === 'Fornecedor' ? '#71b2ed' : '#545454'"
              :size="32"
            />
            <p v-if="msg.from === 'Fornecedor'">{{ msg.contractorName }}:</p>
            <p v-else>{{ msg.userName.split(" ").shift() }}:</p>
            <div class="date">{{ $formatDate(msg.timestamp) }}</div>
          </div>
          <div class="content">
            <div class="icon">
              <ArrowIcon
                :fillColor="msg.from === 'Fornecedor' ? '#71b2ed' : '#545454'"
              />
            </div>
            <div v-if="msg.status === 'Bloqueada'" class="blocked-msg">
              <WarningIcon fillColor="#ED193F" :size="30" />
              <div class="msg">
                Mensagem bloqueada por violar as diretrizes da plataforma.
              </div>
            </div>
            <div v-else class="body">{{ msg.description }}</div>
          </div>
        </div>
      </div>

      <div class="message-input-holder">
        <p class="warning">
          <b>Atenção: </b>as mensagens não podem ser apagadas.
        </p>
        <div class="input-with-button">
          <input
            v-model="newMessage"
            type="text"
            placeholder="Nova mensagem..."
            @keypress.enter="sendMessage"
          />
          <div @click="sendMessage" class="input-btn">
            <SendIcon fillColor="var(--primary)" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.holder {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 620px;
  height: 85vh;
  padding: 0;
  display: grid;
  grid-template-rows: 80px 1fr 98px;
  background-color: #fff;
  margin: 0 auto;
  z-index: 999;
  overflow-y: auto;
  // @media screen and (max-width: 720px) {
  //   max-height: 80vh;
  // }
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    outline: 1px solid slategrey;
  }
  &.mobile {
    border-radius: 10px 10px 0 0;
  }
  &.desktop {
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 14px;
  }
  .title-holder {
    position: relative;
    width: 100%;
    padding: 0 1rem 0.25rem 1rem;
    display: grid;
    grid-template-columns: 50px 1fr 30px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    h1 {
      position: relative;
      font-size: 1rem;
      font-family: fontLight;
      color: var(--primary);
    }
    .close {
      position: relative;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      background-color: #efefef;
      display: grid;
      place-items: center;
      text-align: center;
      color: var(--secondary);
      cursor: pointer;
    }
  }
}

.btn-older-msgs {
  position: relative;
  text-align: center;
  padding: 0.45rem 0;
  border-bottom: 1px solid #b5b5b5;
  color: #565656;
  margin: 0.5rem 0 1rem 0;
}

.icon-holder {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0 0;
  span {
    transition: transform 0.25s ease;
    transform: translate3d(8px, 5px, 0) scale(1.6);
  }
  &::before {
    position: absolute;
    content: "";
    width: 38px;
    height: 38px;
    border-radius: 15px;
    background-color: var(--secondary);
    transform: translate3d(-2px, -2px, 0);
    transition: background-color 0.25s ease;
  }
}

.messages-holder {
  position: relative;
  display: flex;
  overflow-y: auto;
  padding: 10px 1rem 40px 1rem;
  flex-direction: column;
  gap: 1rem;
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    outline: 1px solid slategrey;
  }
  .message {
    position: relative;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #dadada;
    border-radius: 9px;
    &.response {
      border-color: #71b2ed;
      .header p {
        color: #398ad6;
      }
      .content .body {
        color: #1d66a9;
      }
    }
    &.blocked {
      border-color: #ed193f;
      .blocked-msg {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        .msg {
          color: #ed193f;
        }
      }
    }
    .header {
      position: relative;
      display: grid;
      grid-template-columns: 32px 1fr 1fr;
      align-items: center;
      gap: 0.55rem;
      p {
        font-family: fontMedium;
      }
      .date {
        position: relative;
        font-family: fontLight;
        margin-left: auto;
      }
    }
    .content {
      position: relative;
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: flex-start;
      gap: 0.55rem;
      .icon {
        position: relative;
        left: 8px;
      }
      .body {
        font-family: fontLight;
      }
    }
    .check-icon {
      position: absolute;
      right: 0.35rem;
      top: calc(100% - 22px);
      pointer-events: none;
    }
  }
}

.message-input-holder {
  padding: 0 1rem 0 1rem;
  padding-top: 0.3rem;
  box-shadow: 0 -5px 14px -1px rgba(0, 0, 0, 0.1);
  .warning {
    font-size: 12px;
    color: #fa5875;
    margin-top: 10px;
    font-style: italic;
  }
}

.input-with-button {
  position: relative;
  width: 100%;
  height: 44px;
  margin: 5px 0 0 0;
  background-color: #eaeaea;
  border-radius: 8px;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-right: 40px;
    top: 0;
    left: 0;
    color: var(--primary);
    background-color: transparent;
    padding-left: 0.75rem;
    &::placeholder {
      color: var(--dark4);
    }
  }
  .input-btn {
    position: relative;
    width: 45px;
    height: 100%;
    margin: 0 0 0 auto;
    border-radius: 5px;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
</style>
